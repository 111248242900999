import auth from './reducers/auth';
import reports from './reducers/reports'
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  auth,
  reports,
  router: routerReducer
});
