import Base from "./base";

const fields2DB = [
    'name',
    'id'
]

export default class Template extends Base{
    constructor(){
        super();
        this.name = '';
        this.isNew = true;
        this.id = 0;
    }
}

export const filter2DB = (fields) => {
    const result = {};
    Object.entries(fields).forEach(([key, value]) => {
        if(fields2DB.indexOf(key) > -1) result[key] = value;
    });
    return result;
}
