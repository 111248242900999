import React, {Component} from 'react';
import { InputText } from 'primereact/inputtext';
import { Redirect } from 'react-router-dom';
import { Button } from 'primereact/button';
import { LOGIN, LOGIN_ERROR } from './../constants/actionTypes';
import { connect } from 'react-redux';
import agent from '../agent';
import Splash from './../components/Splash';

const mapStateToProps = state => {
    return {
      authorized: state.auth.authorized,
      authError: state.auth.authError,
    }};
  
  const mapDispatchToProps = dispatch => ({
    onLogin: (user, pass) => { 
        agent.Auth.login(user, pass).then(() => {
            document.cookie = `user=${btoa(user)}; max-age=900`;
            document.cookie = `pass=${btoa(pass)}; max-age=900`;
            agent.setToken({email: user, password: pass});
            return agent.User.profile().then(user => dispatch({ type: LOGIN, user: user, authorized: true}));
        }).catch((err) => {
            console.error(err);
            dispatch({ type: LOGIN_ERROR});
        });
    },
  });

const logo = 'assets/layout/images/logo.png';
class LoginPage extends Component {

    constructor(){
        super();
        this.state = { userEmpty: false, passEmpty: false, splash: true, timer: 0 }
    }

    onKeyUp(target, e){
        if(e.keyCode === 13){
            this.authorize();
        }
        const state = {};
        state[target.name] = target.value;
        this.setState(state);
    }

    authorize() {        
        const userEmpty = !this.state.user || this.state.user.length === 0,
            passEmpty = !this.state.pass || this.state.pass.length === 0;
        if(userEmpty || passEmpty){
            this.setState({userEmpty: userEmpty, passEmpty: passEmpty});
        }
        else {
            this.props.onLogin(this.state.user,this.state.pass);
        }
    }

    componentWillUnmount(){
        this.setState({timer: 0, splash: false});
        clearTimeout(this.state.timer);
    }

    componentDidMount(){
        this.setState({timer: setTimeout(()=>{this.setState({splash: false})}, 1000)})
    }



    render (){

        const {authError} = this.props;
        const {userEmpty, passEmpty, splash} = this.state;

        if(this.props.authorized) {
            return <Redirect to='/'/>
        }

        if(splash) {
            return <Splash></Splash>
        }
    
        return <div className="login-form p-grid">
            
            <div className="p-col-12 p-sm-8 p-sm-offset-2 p-md-3 p-md-offset-8">
                <div className="p-col-12">
                    <img style={{maxWidth: '100%', margin: 'auto', maxHeight: '40px'}} alt="Logo" src={logo} />
                </div>
                <div className="p-col-12">
                    <h1>Авторизация</h1>
                </div>
                <div className="p-col-12">
                    <div className={`p-inputgroup ${userEmpty ? 'error-field' : ''}`}>
                        <InputText name = "user" onKeyUp= {e => this.onKeyUp(e.target, e)} placeholder="email" />
                    </div>
                </div>
                <div className="p-col-12">
                    <div className={`p-inputgroup ${passEmpty ? 'error-field' : ''}`}>
                        <InputText name = "pass" onKeyUp= {e => this.onKeyUp(e.target, e)} type="password" placeholder="Пароль" />
                    </div>
                </div>

                {
                    authError && <div className="p-col-12">
                        <span style={{color: '#ff4859'}}>Неверный email или пароль</span>
                    </div>
                }
                
                <div className="p-col-12">
                    <Button label ="Войти в систему"  onClick = {()=>this.authorize()}></Button>
                </div>
            </div>
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);


