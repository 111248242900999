export default class Base{   
    constructor(){
        this.fields2DB = []

        this.set = (values) => {
            const o = JSON.parse(JSON.stringify(this));
            return Object.assign(o, values);
        }
        
        this.filter2DB = (data) => {
            const result = {};
            Object.entries(data).forEach(([key, value]) => {
                if(this.fields2DB.indexOf(key) > -1) result[key] = value;
            });
            return result;
        }
    }
}