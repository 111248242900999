import Base from "./base";

export default class Network extends Base{
    constructor(){
        super();

        this.name = '';
        this.newValue = '';
        this.description = '';
        this.listValues = [{value: ''}];
        this.isNew = true;
        this.agents = [];
        this.id = 0;
        this.templates = [];
        this.templatesChecked = [];
        this.seeAll = false;

        this.fields2DB = [
            'name',
            'description',
            'listValues',
            'id'
        ]
    }
}