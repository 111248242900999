import React, {Component, Fragment} from 'react';
import agent from './../../agent';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { changeState, guid } from '../../utils/utils';
import {MultiSelect} from 'primereact/multiselect';


import Splash from '../Splash';
import { Redirect } from 'react-router-dom';

export default class RuleComponent extends Component {

    constructor(){
        super();
        this.state = this.getInitialState();
    }

    getInitialState(){
        return { name:'', fields: {}, errors: [], saved: false, templates:[], results:[], users:[], lists:[]}
    }

    onChangeState(target){
        const errors = this.state.errors.filter(a => a !== target.name);
        this.setState({errors});
        changeState(target, this);
    }
    
    onAddUser(target){
        let differenceA = this.state.users.filter(x => !target.value.includes(x));
        let differenceB = target.value.filter(x => !this.state.users.includes(x));
        if(differenceB.length > 0){
            agent.Rules.addUser(this.state.id, differenceB[0]).then(()=>{});
        }
        if(differenceA.length > 0){
            agent.Rules.removeUser(this.state.id, differenceA[0]).then(()=>{});
        }
        this.onChangeState(target);
    }

    UNSAFE_componentWillReceiveProps(props){
        if(props.match.params.id > 0 && props.match.params.id !== this.props.match.params.id) { 
            this.setState(this.getInitialState(), ()=>{
                this.load(props.match.params.id);   
            });                 
        }
    }

    componentDidMount(){ 
        agent.Template.list().then(templatesData => {
            const templates = templatesData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id + '', label: curr.name}]);
            }, []);
            this.setState({templates}, ()=>{
                agent.Property.one(-1).then(resultsData => {
                    const results = resultsData.listValues.reduce((prev, curr) => {
                        return prev.concat([{value: curr.id + '', label: curr.name}]);
                    }, []);
                    results.unshift({label: 'Любое значение', value: '-1'})
                    this.setState({results}, ()=>{
                        this.load(this.props.match.params.id) 
                    })
                })
            })
        })   
    }

    load(id){
        if(Number.parseInt(id) > 0 && id !== 'create'){
            
            agent.Rules.one(id).then((data) => {
                const rules = data.rules.reduce((prev, curr) => {
                    prev[curr.id + ''] = curr.values;
                    if(['network', 'template', 'market', 'result'].indexOf(curr.id) === -1){
                        data['#' + curr.id] = curr.values;
                    }
                    return prev;
                }, {});
                

                if(rules['network'] && rules['network'].length > 0) this.loadMarkets(rules['network'], rules.market);
                if(rules['template'] && rules['template'].length > 0) this.loadTemplate(rules['template'][0]);
                data['result'] = (rules['result'] && rules['result'].length > 0) ? rules['result'][0] : '-1';
                this.setState(data);
            }).then(() => {
                agent.User.list().then(usersResponse => {
                    const usersData = usersResponse.reduce((prev, curr) => {
                        return prev.concat([Object.assign(curr, {label: curr.surname + ' ' + curr.name + ' ' + curr.secondname, value: curr.id})]);
                    }, []);
                    this.setState({usersData});
                }).then(() => {
                    agent.Rules.users(id).then(usersResponse => {
                        const users = usersResponse.reduce((prev, curr) => {
                            return prev.concat([curr.id]);
                        }, []);
                        this.setState({users});
                    })
                })
            })
        } else {
            this.setState({rules: [], id: 0, result: '-1', name:''})
        }

        
        this.loadNetworks()
    }

    loadTemplate(id){
        const state = Object.keys(this.state).reduce((object, key) => {
            if (key.indexOf("$.") === -1) {
                object[key] = this.state[key]
            } else {
                object[key] = undefined;
            }
            return object
          }, {})

        agent.Template.one(id).then((template)=>{
            const fields = {};
            template.templateFields.forEach(field => {
                if(!fields[field.templateFieldType.type]) {
                    fields[field.templateFieldType.type] = [];
                }
                fields[field.templateFieldType.type].push(field);

                if(field.templateFieldType.type === 'LIST') {
                    const values = {};
                    state[`$.${field.templateFieldType.type}.` + field.id] = [];
                    if(typeof this.state['#' + field.id] !== 'undefined'){
                        values[field.id] = {value: this.state['#' + field.id], key: `$.${field.templateFieldType.type}.` + field.id}
                    }
                    agent.Property.one(field.listId).then(resultsData => {
                        const list = resultsData.listValues.reduce((prev, curr) => {
                            return prev.concat([{value: curr.id + '', label: curr.name}]);
                        }, []);
                        
                        const lists = this.state.lists || {};
                        lists[field.id] = list;
                        this.setState({lists}, () => {
                            const stateTmp = {};
                            if(values[field.id]){
                                let valuesTmp = values[field.id].value.map(v=>{// eslint-disable-line
                                    const listValue = (list.find(a=>a.label === v));
                                    if(typeof listValue !== 'undefined'){
                                        return listValue.value;
                                    }
                                });
                                valuesTmp = valuesTmp.filter(a=>a !== null && a !== undefined)
                                stateTmp[values[field.id].key] = valuesTmp;
                                this.setState(stateTmp);
                            }
                            
                        })
                    })
                }
            });
            this.setState({fields, template: id});
        })
    }

    loadMarkets(id, market=false){

        const p = new Promise((resolve, reject) => {
            let markets = [];
            let marketsData = [];
            let limit = 0;
            id.forEach(i => {
                agent.Market.list(i).then(marketsDataTmp => {
                    const marketsTmp = marketsDataTmp.reduce((prev, curr) => {
                        return prev.concat([{value: curr.id + '', label: curr.name, city: curr.city}]);
                    }, []);
                    marketsData = marketsData.concat(marketsDataTmp);
                    markets = markets.concat(marketsTmp);

                    if(limit === id.length - 1){
                        resolve({markets, marketsData, id, market});
                    }
                    limit++;
                })
            })
        })

        p.then(result => {            
            this.setState({
                markets: result.markets, 
                marketsData: result.marketsData, 
                network: result.id, 
                market: result.market
            })
        })

    }

    loadNetworks(){
        agent.Network.list().then(networksData => {
            const networks = networksData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id + '', label: curr.name}]);
            }, []);
            this.setState({networks})
        })
    }
    
    save(){
        const {template, network, market, result, id, lists} = this.state;
        const rules = [];
        const rawKeys = Object.keys(this.state).map(key => key).filter(a => a.indexOf('$') === 0);

        rawKeys.forEach(key=>{
            if(this.state[key] !== undefined){
                if( this.state[key] !== ''){
                    if(key.indexOf('$.') > -1){
                        const arrKey = key.split('.');
                        if(arrKey[1] === "DATE"){
                            rules.push({id: arrKey[2], values: this.state[key].getTime()})
                        } else if(arrKey[1] === "BOOLEAN"){
                            rules.push({id: arrKey[2], values: this.state[key] === true ? '1' : '0'})
                        } else if(arrKey[1] === "LIST"){
                            const values = [];
                            this.state[key].forEach(k => 
                                { values.push(lists[arrKey[2]].find(a => a.value === k).label); }
                            )
                            if(values.length > 0){
                                rules.push({id: arrKey[2], values})
                            }
                        } else {
                            rules.push({id: arrKey[2], values: this.state[key] + ''})
                        }
                    } else {
                        rules[key.replace('$','')] = this.state[key]
                    }
                }
            }
        })

        if( typeof template !== 'undefined' && template !== false ) rules.push({id:'template', values: [template] });
        if( typeof network !== 'undefined' && network !== false ) rules.push({id:'network', values: network });
        if( typeof market !== 'undefined' && market !== false ) rules.push({id:'market', values: market });
        if( result !== "-1" ) rules.push({id:'result', values: [result]});
        
        const rule = {name: this.state['name'] || guid(), rules}

        if( id === 0 ){
            agent.Rules.create(rule).then(
                (result) => {
                    this.setState({saved: result.id})
                    }
                )
        } else{
            agent.Rules.update(id, rule).then(
                (result) => {
                    this.setState({saved: result.id})
                    }
                )
        }
    }

    onDelete(){
        if(window.confirm('Действительно удалить правило ' + this.state.name + '?'))
        agent.Rules.delete(this.state.id).then(() => {this.setState({deleted: true})});
    }
    
    templateItem(option) {
        if (!option.value) {
            return option.label;
        }
        else {    
            return (
                <div className="p-clearfix">
                    <span>{option.label}</span>
                    <p style={{fontSize: '10px', marginTop: '4px', marginBottom: '0px'}}>{option.city}</p>
                </div>
            );
        }
    }

    render = () => {

        const {
            networks, 
            markets, 
            templates, 
            template, 
            network, 
            market, 
            fields, 
            lists, 
            errors, 
            saved, 
            results, 
            name, 
            id, 
            deleted, 
            usersData
        } = this.state;

        if(saved && id === 0 && !Number.isInteger(this.props.match.params.id)){
            return <Redirect to={'/rules/' + saved}></Redirect>
        }

        if(!!deleted){
            return <Redirect to={'/rules/'}></Redirect>
        }

        return (networks && 
        <div className="p-grid">
            <h2 className="p-col-12">Правило #{this.props.match.params.id}</h2>
            <div className="p-col-12 report report_mail card">
                <div className="p-grid">
                    <div className="p-col-12">
                        <InputText 
                            className = "p-col-12"
                            name = 'name'
                            style={{lineHeight: '2em', marginBottom: '1em'}}
                            onChange = {e => this.onChangeState(e.target)}  
                            value = {name} 
                            placeholder = 'Название' 
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <MultiSelect 
                            className="p-col-12" 
                            name="$network" 
                            defaultValue={network} 
                            allChecked = {true}
                            value={network} 
                            options={networks} 
                            onChange={(e) => this.loadMarkets(e.target.value)}  
                            placeholder="Выбрать торговую сеть" 
                            filter={true} 
                            filterBy="label"
                            />
                    </div>
                    {markets && <div className="p-col-12 p-md-6">
                            <MultiSelect 
                                className="p-col-12" 
                                name="$market" 
                                allChecked = {true}
                                defaultValue={market} 
                                itemTemplate={this.templateItem} 
                                value={market} options={markets} 
                                onChange={(e) => this.setState({market: e.target.value})}  
                                placeholder="Выбрать гипермаркет" 
                                filter={true} 
                                filterBy="label"
                            />
                        </div>
                    }
                    {templates && <div className="p-col-12 p-md-6">
                            <Dropdown 
                                className="p-col-12" 
                                name="$template" 
                                defaultValue={template} 
                                value={template} options={templates} 
                                onChange={(e) => this.loadTemplate(e.target.value)}  
                                placeholder="Выбрать шаблон" 
                                filter={true} 
                                filterBy="label"
                            />
                        </div>
                    }
                </div>
                {
                    <Fragment> 
                        <div className="p-grid">  
                            {
                                results && <div className="p-col-12 p-md-6">
                                    <Dropdown 
                                        className="p-col-12"  
                                        name = "result" 
                                        onChange= {e => this.onChangeState(e.target)}  
                                        value={this.state['result']} 
                                        options={results}
                                    />
                                </div>
                            }
                            {Object.entries(fields).map(([key, fieldsData]) => 
                                {
                                    return fieldsData.map((field) => {
                                        if(key === "LIST"){
                                            return <div key={'key.custom.' + field.id} className={"p-col-12 p-md-6" + (errors.indexOf(`$.${key}.` + field.id) > -1 ? ' error-field' : '') }>
                                                {
                                                    lists && <MultiSelect 
                                                        className = "p-col-12"
                                                        name = {`$.${key}.` + field.id} 
                                                        key={`$.${key}.` + field.id}
                                                        allChecked={true}
                                                        onChange = {e => this.onChangeState(e.target)}
                                                        value={this.state[`$.${key}.` + field.id]}
                                                        placeholder={field.name}
                                                        options={lists[field.id]}
                                                        filter={true} 
                                                        filterBy="label"
                                                    />
                                                }
                                                
                                            </div>}
                                        else if(key === "BOOLEAN"){
                                            return <div key={'key.custom.' + field.id} className={"p-col-12 p-md-6" + (errors.indexOf(`$.${key}.` + field.id) > -1 ? ' error-field' : '') }>
                                                <InputSwitch 
                                                    name = {`$.${key}.` + field.id} 
                                                    onChange= {e => this.onChangeState(e.target)}  
                                                    checked = {this.state[`$.${key}.` + field.id]}
                                                />
                                                <span>{field.name}</span>
                                            </div>}
                                        else return null;
                                    }                                       
                                )}
                            )}

                            {id > 0 && <div className = "p-col-12">
                                    {
                                        usersData && <MultiSelect 
                                            className = "p-col-12"
                                            name = 'users' 
                                            allChecked={true}
                                            onChange = {e => { this.onAddUser(e.target); }}
                                            value={this.state['users']}
                                            placeholder='Пользователи'
                                            options={usersData}
                                            filter={true} 
                                            filterBy="label"
                                        />
                                    }
                                </div>}
                        </div>
                    </Fragment>
                }
                <div className="p-grid" style={{marginTop: '1em'}}>
                    <div className="p-col-6">
                        <Button icon="pi pi-save" className="p-button-success" onClick={() => this.save()} label="Сохранить"></Button>
                    </div>
                    {id > 0 && <div className="p-col-6" style={{textAlign: 'right'}}>
                        <button onClick={()=>{this.onDelete()}}className="p-button p-component p-button-danger p-button-text-icon-left">
                            <span className="pi pi-trash p-c p-button-icon-left"></span>
                            <span className="p-button-text p-c">Удалить правило</span>
                        </button>
                    </div>}
                </div>
            </div>       
        </div>) || <Splash></Splash>
    }
}