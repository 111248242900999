import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { Agent } from '../Agent';
import { changeState, guid } from './../../utils/utils';
import Market from '../../constants/types/market';
import agent from './../../agent';

export default class MarketDialog extends Component {

    constructor(){
        super();
        this.state = {agentsValues: {}, ...(new Market())}
    }

    onAddAgent(){
        const agents = this.state.agents;
        const guidValue = guid();
        agents[guidValue] = {
            fio: '',
            mail: '',
            expert: false,
            subscriber: false,
            id: guidValue,
            onRemove: (key) => this.onRemoveAgent(key)
        }
        this.setState({agents});
    }
    
    onRemoveAgent(key){
        const {agents, agentsValues} = this.state;
        delete agentsValues[key]
        delete agents[key];
        this.setState({agents, agentsValues});
    }   
    
    onChangeAgent(agent){
        const agentsValues = this.state.agentsValues;
        agentsValues[agent.id] = agent;
        this.setState({agentsValues});
    }

    loadUsers(){
        agent.User.list().then(users => {
            const usersData = users.reduce((prev, curr) => {
                return prev.concat([Object.assign(curr, {label: curr.surname + ' ' + curr.name + ' ' + curr.secondname, value: curr.id})]);
            }, [{label: 'Новый пользователь', value: 0}]);
            this.setState({users: usersData})
        })
        agent.User.rolesList().then(roles => {
            roles = roles.filter(a => a.id !== 1 )
            this.setState({roles})
        })
    }
    addAgentRoles(user, roles, market){
        roles.map(
            role => agent.User.roles(user, {role: {id: role}, market: {id: market}, trafeNetwork: {id: this.props.network}}).then(()=>{})
        )
    }
    
    loadMarket(id){
        agent.Market.one(this.props.network, id).then((market)=>{
            market['isNew'] = false;
            this.setState((new Market()).set(market))
        })
    }

    onCreate(){
        const {agentsValues} = JSON.parse(JSON.stringify(this.state));
        const market = this.state.filter2DB(this.state);
        agent.Market.create(this.props.network, market).then(
            (data) => {
                Object.entries(agentsValues).forEach(([key, value])=>{
                    if(value.user > 0 ){
                        this.addAgentRoles(value.user, value.roles, data.id)
                    } else {
                        if(value.mail.length > 0 && value.fio.length > 0){
                            const fio = value.fio.split(' ');
                            const user  = {
                                surname: fio[0] || '',
                                name: fio[1] || '',
                                secondname: fio[2] || '',
                                mail: value.mail,
                                password: guid('pass')
                            };
                            agent.User.create(user).then(user => this.addAgentRoles(user.id, value.roles, data.id))
                        }
                    }                    
                })
                this.props.onUpdate();
            }
        )
    }
    onUpdate(){
        const property = this.state.filter2DB(this.state);
        agent.Market.update(this.props.network, property.id, property).then(
            () => this.props.onUpdate()
        )
    }

    onDelete(){
        const property = this.state.filter2DB(this.state);

        if(window.confirm(`Действительно удаляем ${this.state.name}`)){
            agent.Market.delete(this.props.network, property.id).then(
                () => this.props.onUpdate()
            )
        }
    }

    componentDidMount(){
        if(this.props.market !== 0){
            this.loadMarket(this.props.market);
        }
        this.loadUsers();
    }

    onChangeState(target){
        changeState(target, this);
    }

    render () {

        const {onHide} = this.props;
        const {agents, name, city, description, isNew, id, roles, users} = this.state;

        const footer = (
            <div className="p-grid modal-form">
               <div className="p-col-6" style={{textAlign: "left"}}>
                   { ( !isNew && id > 0 )&& <Button className="p-button-danger" label="Удалить сеть" icon="pi pi-trash" onClick={() => this.onDelete()}/>}
               </div>
               <div className="p-col-6">
                   {id > -1 && <Button label="Сохранить" icon="pi pi-check" onClick={() => (id > 0 ? this.onUpdate() : this.onCreate())} />}
               </div>
           </div>
   );


        return (
            <Dialog visible={true} style={{width: '70vw'}} header="Магазин" footer={footer} modal={true} onHide={()=>onHide()}>
                <div className="p-grid modal-form">
                    <div className="p-col-12">
                        <Fieldset legend="Основное">
                            <div className="p-grid">
                                <InputText defaultValue = {name} name = "name" onChange = {e => this.onChangeState(e.target)} className="p-col-12 p-md-6" placeholder="Название гипермаркета" />                           
                                <InputText defaultValue = {city} name = "city" onChange = {e => this.onChangeState(e.target)} className="p-col-12 p-md-6" placeholder="Город" />                           
                                <InputTextarea defaultValue = {description} name = "description" onChange = {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Краткое описание" />                           
                            </div>
                        </Fieldset>
                    </div>
                    {isNew && <div className="p-col-12">
                        <Fieldset legend="Представители">
                            <div className="p-grid">
                                {(roles && users) && Object.entries(agents).map(([key, agent]) => <Agent roles = {roles} onChange = {agent => this.onChangeAgent(agent)} key={key} users={users} {...agent}/>)}
                                <div className="p-col-12">
                                    <Button onClick={()=>this.onAddAgent()} label="Добавить представителя"></Button>
                                </div>
                            </div>
                        </Fieldset>
                    </div>}
                </div>
            </Dialog>
        )    
    }

}