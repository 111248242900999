import React, {Component} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
import { changeState, guid } from './../../utils/utils';
import User from '../../constants/types/user';
import agent from './../../agent';
import { InputText } from 'primereact/inputtext';

export default class UserRolesDialog extends Component {
    constructor(){
        super();
        this.state = {user: new User(), rolesChecked: {tradeNetwork: {}, market: {}}, markets: {},search:''}
    }

    loadUser(){
        agent.User.one(this.props.user).then((user)=>{
            const rolesChecked = this.state.rolesChecked;
            user.userRoles.filter(a => a.tradeNetwork && !a.market).forEach(roleValue => {
                if(!rolesChecked.tradeNetwork){
                    rolesChecked.tradeNetwork = {};
                }
                if(!rolesChecked.tradeNetwork[roleValue.tradeNetwork.id]){
                    rolesChecked.tradeNetwork[roleValue.tradeNetwork.id] = [];
                }
                rolesChecked.tradeNetwork[roleValue.tradeNetwork.id].push(roleValue.role.id);
            })
            user.userRoles.filter(a => a.market).forEach(roleValue => {
                if(!rolesChecked.market){
                    rolesChecked.market = {};
                }
                if(!rolesChecked.market[roleValue.market.id]){
                    rolesChecked.market[roleValue.market.id] = [];
                }
                rolesChecked.market[roleValue.market.id].push(roleValue.role.id);
            })
            this.setState({user: (new User()).set(user), rolesChecked})
        })
    }

    componentDidMount(){
        this.loadUser();
    }
    
    onChangeState(target){
        changeState(target, this);
    }

    onSetRole(e, key, entity, entityValue){
        let {rolesChecked} = this.state;
        if(e.value){
            const roleValue = {role: {id: key}};
            roleValue[entity] = {id: entityValue};
            agent.User.roles(this.state.user.id, roleValue)
                .then(()=>{
                    if(!rolesChecked[entity]){rolesChecked[entity] = {};}
                    if(!rolesChecked[entity][entityValue]){rolesChecked[entity][entityValue] = [];}
                    rolesChecked[entity][entityValue].push(key);
                    this.setState({rolesChecked}, this.loadUser())
                })
        } else {
            const role = this.state.user.userRoles.find(a=>a.role.id === key);
            agent.User.removeRole(this.state.user.id, role.id).then(()=>{
                rolesChecked = rolesChecked[entity][entityValue].filter(a => a !== key);
                this.setState({rolesChecked}, this.loadUser());
            })
        }
    }
    
    loadMarkets(networkId){

        const {markets} = this.state;

        if(!markets[networkId]){
            agent.Market.list(networkId).then((marketsData) => {
                markets[networkId] = marketsData;
                this.setState({
                    byMarket: true,
                    networkId,
                    markets
                })
            })
        } else {
            this.setState({
                byMarket: true,
                networkId
            })
        }
    }
    onSearch(e){
        this.setState({search: e.target.value})
    }
    render () {

        const {visible, onHide, roles, networks} = this.props;
        const {byMarket, markets, search, networkId, rolesChecked} = this.state;

        return (
            <Dialog visible={visible} header={`Управление доступами к гипермаркетам`}style={{minWidth: '600px'}} modal={true} onHide={()=>onHide()}>
               
                {byMarket && <div className="p-grid">
                    <div className="p-col">
                        <Button icon="pi pi-chevron-left" label="Торговые сети" onClick={()=>{this.setState({search: '', byMarket: false})}}></Button>
                    </div>
                    <div className="p-col" style={{textAlign: "right"}}>
                        <InputText type="text" placeholder="Поиск" onChange={(e) => this.onSearch(e)}></InputText>
                    </div>
                </div> }

                <div className="p-grid">
                    {
                        !byMarket && networks.map(network => <div key={network.id} className="p-col-6"> 
                                <Fieldset legend={network.name}>
                                    {roles.filter(role => role.id > 1).map(role => 
                                        <div className="p-grid switches" key={guid()}>
                                            <div className = "p-col-12">
                                                <InputSwitch checked={rolesChecked.tradeNetwork && rolesChecked.tradeNetwork[network.id] && rolesChecked.tradeNetwork[network.id].indexOf(role.id) > -1} onChange={(e) => this.onSetRole(e, role.id, 'tradeNetwork', network.id)} />
                                                <span>{role.description}</span>
                                            </div>
                                        </div>
                                    )}
                                    <Button onClick={() => this.loadMarkets(network.id)} label="Гипермаркеты"></Button>
                                </Fieldset>
                            </div>)
                    }
                    {byMarket && markets[networkId].filter(market => market.name.indexOf(search) > -1).map(market => <div key={market.id} className="p-col-6"> 
                        <Fieldset legend={market.name}>
                            {roles.filter(role => role.id > 1).map(role => 
                                <div className="p-grid switches" key={guid()}>
                                    <div className = "p-col-12">
                                        <InputSwitch checked={rolesChecked.market && rolesChecked.market[market.id] && rolesChecked.market[market.id].indexOf(role.id) > -1} onChange={(e) => this.onSetRole(e, role.id, 'market', market.id)} />
                                        <span>{role.description}</span>
                                    </div>
                                </div>
                            )}
                        </Fieldset>
                    </div>)
                    }
                </div>
            </Dialog>
        )    
    }

}