import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Fieldset} from 'primereact/fieldset';
import {Card} from 'primereact/card';
import { Button } from 'primereact/button';
import UserDialog from './UserDialog';
import UserRolesDialog from './UserRolesDialog';
import UserMailsDialog from './UserMailsDialog';
import agent from './../../agent';

export class Users extends Component {

    constructor(){
        super();
        this.state = {users: [], roles: [], dialog: false, user: 0};
    }

    loadUsers(){
        agent.User.list().then((users)=>{
            users.forEach((user, id) => {
                users[id]['user'] = `${user.surname} ${user.name} ${user.secondname}`;
            });
            this.setState({users: users})
        })
    }

    onUpdateUsers(){
        this.setState({dialog: false}, () => this.loadUsers())
    }

    componentDidMount(){
        this.loadUsers();
        agent.Network.list().then((networks) => this.setState({networks}))
        agent.User.rolesList().then((roles)=>{
            let rolesList = [];
            roles.forEach((role, id) => {
                rolesList.push({
                    label: role.description,
                    value: role.id,
                })
            });
            this.setState({rolesList, roles})
        })
    }

    showDialog(e){
        this.setState({dialog: true})
        if(e){
            this.setState({dialog: true, user: e.data.id})
        } else{
            this.setState({dialog: true, user: 0})
        }
    }
    showDialogRoles(e){
        this.setState({dialogRoles: true})
        if(e){
            this.setState({dialogRoles: true, user: e.data.id})
        } else{
            this.setState({dialogRoles: true, user: 0})
        }
    }
    showDialogMails(e){
        this.setState({dialogMails: true})
        if(e){
            this.setState({dialogMails: true, user: e.data.id})
        } else{
            this.setState({dialogMails: true, user: 0})
        }
    }

    render() {

        const {roles, networks, rolesList, users} = this.state;
        const action = (rowData) => {
            return <div>
                <Button type="button" icon="pi pi-envelope" onClick={()=>this.showDialogMails({data:rowData})} className="p-button-primary"></Button>
                {' '}
                <Button type="button" icon="pi pi-key" onClick={()=>this.showDialogRoles({data:rowData})} className="p-button-secondary"></Button>
                {' '}
                <Button type="button" icon="pi pi-pencil" onClick={()=>this.showDialog({data:rowData})} className="p-button-warning"></Button>
            </div>;
        }

        const header = <div className="p-grid">
                        <div style={{'textAlign':'left'}} className="p-col-9">
                            <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Поиск по всем полям" size="50"/>
                            </div>
                        <div style={{'textAlign':'right'}} className="p-col-3">
                            <Button onClick = { () => this.showDialog()} label="Добавить пользователя"></Button>
                        </div>
                    </div>;

        return <Card>
                <Fieldset legend="Пользователи">
                {rolesList && <DataTable value={users} 
                        style={{marginBottom: '20px'}} 
                        responsive={true} 
                        paginator={true} 
                        rows={10} 
                        header={header} 
                        
                        globalFilter={this.state.globalFilter} 
                        emptyMessage="Нет пользователей с такими параметрами"
                    >
                        <Column style={{width: '80px', textAlign: 'center'}} field="id" header="#" filter = {true} sortable={true} />
                        <Column field="user" header="Пользователь" filter = {true} sortable={true} />
                        <Column field="mail" header="Email" filter = {true} sortable={true} />

                        <Column body={action} style={{textAlign:'center', width: '10em'}}/>
                    </DataTable>
                    }
                </Fieldset> 
                {this.state.dialog && 
                    <UserDialog roles={roles} user={this.state.user} visible = {true} onUpdate = {() => this.onUpdateUsers()} onHide = {() => this.setState({dialog: false})}/>}
                {this.state.dialogRoles && 
                    <UserRolesDialog networks={networks} roles={roles} user={this.state.user} visible = {true} onUpdate = {() => this.onUpdateUsers()} onHide = {() => this.setState({dialogRoles: false})}/>}
                {this.state.dialogMails && 
                    <UserMailsDialog networks={networks} roles={roles} user={this.state.user} visible = {true} onUpdate = {() => this.onUpdateUsers()} onHide = {() => this.setState({dialogMails: false})}/>}
                    
            </Card>
    }
}