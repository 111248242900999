import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';

import agent from '../../agent';
import { guid } from '../../utils/utils';

export default class ReportsCategories extends Component {

    constructor(){
        super();
        this.state = {markets: [], templates: []};
    }

    loadMarkets(){
        this.networkId = this.props.match.params.network;
        
        agent.Market.list(this.networkId)
            .then((markets) => this.setState({markets}));
        
        agent.Network.templates(this.networkId)
            .then((templates)=> this.setState({templates}));
    }
    
    componentDidMount() {
        this.setState({
            activeIndex: this.props.match.params.type === 'templates' ? 1 : 0
        })
        this.loadMarkets();
    }

    render() {
        const {markets, templates} = this.state;
        
        return (
            <div className="p-grid">
                <div className="p-col-12"><h2>Выберите категорию отчетов:</h2></div>
                <TabView className="p-col-12" activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                    <TabPanel header="По гипермаркетам">
                        <div className="p-grid">
                            {
                                markets.map(
                                    market => (
                                        <Link key = {guid()} to={`/reports/${this.networkId}/markets/${market.id}`} className="p-col-6 p-md-4">
                                            <div className="report card">
                                                {market.name}
                                            </div>
                                        </Link>
                                    )
                                )
                            }
                        </div>
                    </TabPanel>
                    <TabPanel header="По шаблонам">
                    <div className="p-grid">
                            {
                                templates.map(
                                    template => (
                                        <Link key = {guid()} to={`/reports/${this.networkId}/templates/${template.id}`} className="p-col-6 p-md-4">
                                            <div className="report card">
                                                {template.name}
                                            </div>
                                        </Link>
                                    )
                                )
                            }
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        )    
    }
}