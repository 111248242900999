import Base from "./base";

export default class Network extends Base{
    constructor(){
        super();

        this.name = '';
        this.city = '';
        this.description = '';
        this.agents = [];
        this.isNew = true;
        this.id = 0;

        this.fields2DB = [
            'city',
            'name',
            'description',
            'id'
        ]
    }
}