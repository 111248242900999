import React, {Component} from 'react';
import { Button } from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { changeState } from './../../utils/utils';
import Property from '../../constants/types/property';
import agent from './../../agent';

export default class PropertyDialog extends Component {
    constructor(){
        super();
        this.state = new Property()
    }

    loadProperty(id){
        agent.Property.one(id).then((property)=>{
            property['isNew'] = false;
            property.listValues = this.state.getUnPatchedListValues(property.listValues);
            this.setState(new Property().set(property))
        })
    }

    componentDidMount(){
        if(this.props.property !== 0){
            if(this.props.property !== 0 && this.props.visible === true){
                this.loadProperty(this.props.property);
            }
        }
    }
    
    onChangeState(target){
        changeState(target, this);
    }

    onChangeListValue(value, key){
        const {listValues} = this.state;
        listValues[key] = value;
        this.setState({listValues})
    }

    onCreate(){
        const property = this.state.filter2DB(this.state);
        property.listValues = this.state.getPatchedListValues(property.listValues);
        agent.Property.create(property).then(
            () => this.props.onUpdate()
        )
    }
    onUpdate(){
        const property = this.state.filter2DB(this.state);
        property.listValues = this.state.getPatchedListValues(property.listValues);
        agent.Property.update(property.id, property).then(
            () => this.props.onUpdate()
        )
    }

    onDelete(){
        if(window.confirm(`Действительно удаляем ${this.state.name}`)){
            agent.Property.delete(this.state.id).then(
                () => this.props.onUpdate()
            )
        }
    }

    onAddValue(){
        const listValues = this.state.listValues;
        listValues.push('')
        this.setState({listValues: listValues});
    }
    
    render () {

        const {visible, onHide} = this.props;
        const {isNew, name, listValues, id} = this.state;

        const footer = (
                 <div className="p-grid modal-form">
                    <div className="p-col-6" style={{textAlign: "left"}}>
                        { ( !isNew && id > 0 )&& <Button className="p-button-danger" label="Удалить список" icon="pi pi-trash" onClick={() => this.onDelete()}/>}
                    </div>
                    <div className="p-col-6">
                        <Button className="p-button-secondary"  label="Отменить" icon="pi pi-times" onClick={() => onHide()} />
                        {id > -1 && <Button label="Сохранить" icon="pi pi-check" onClick={() => (id > 0 ? this.onUpdate() : this.onCreate())} />}
                    </div>
                </div>
        );

        return (
            <Dialog style={{width: '70vw'}} visible={visible} header={isNew ? 'Новый список' : `Редактирование списка`} footer={footer} modal={true} onHide={()=>onHide()}>
                
                <div className="p-grid modal-form">
                    <div className="p-col-12">
                        <Fieldset legend="Основное">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-lg-4">
                                <InputText defaultValue={name} name = "name" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Название списка" />
                            </div>
                        </div>
                        </Fieldset>
                    </div>
                    <div className="p-col-12">
                        <Fieldset legend="Значение свойств">
                            <div className="p-grid">
                                {listValues.map((el, key)=><div className="p-col-12 p-md-6 p-lg-4"  key={key}><InputText className="p-col-12" readOnly={id === -1} onKeyUp = {e => this.onChangeListValue(e.target.value, key)} defaultValue={el} placeholder={"Значение "  + (key + 1)} /></div>)}  
                                <div className="p-col-12 ">{id > -1 && <Button onClick={() => this.onAddValue()} label="Добавить значение"></Button>}</div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
            </Dialog>
        )    
    }

}