import React, {Component} from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { changeState } from './../../utils/utils';
import agent from './../../agent';

export class Profile extends Component {
    constructor(){
        super();
        this.state = {password: '', password_again: ''}
    }


    componentDidMount(){
        agent.User.profile().then((user)=>{
            this.setState({user})
        })
    }
    
    onChangeState(target){
        if(target.name.indexOf('password') > -1){
            this.setState({changePassword: target.value.length > 0, userPasswordError: false})
        } 
        changeState(target, this);
    }

    onUpdate(){
        const {changePassword, password, password_again} = this.state;
        
        if(changePassword){
            if(password !== password_again){
                this.setState({userPasswordError: true})
            } else {
                agent.User.profilePassword(password).then(
                    this.setState({changePassword: false, password: '', password_again: '', saved: true})
                )
            }
        }
    }

    render () {

        const {user, changePassword, password, password_again} = this.state;

        return (user && (
            <div className="card">
                <div className="p-grid modal-form">
                    <div className="p-col-6">
                        <Fieldset legend="Основное">
                            <InputText readOnly={true} defaultValue={user.surname} name = "user.surname" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Фамилия" />
                            <InputText readOnly={true} defaultValue={user.name} name = "user.name" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Имя" />
                            <InputText readOnly={true} defaultValue={user.secondname} name = "user.secondname" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Отчество" />
                            <InputText readOnly={true} defaultValue={user.mail} name = "user.mail" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="email" />                            
                        </Fieldset>
                    </div>
                    <div className="p-col-6">
                        <Fieldset legend="Пароль">
                            <InputText type="password" value={password} name = "password" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Пароль" />
                            <InputText type="password" value={password_again} name = "password_again" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Пароль ещё раз" />

                            {this.state.userPasswordError && <div><span className="label-danger"></span>Пароли не совпадают</div>}
                            {this.state.saved && <div><span className="label-success"></span>Пароль успешно обновлён</div>}

                            <div style={{textAlign: "right"}}>
                                {(changePassword && password === password_again) && <Button label="Изменить пароль" icon="pi pi-check" onClick={() => this.onUpdate()} />}
                            </div>
                        </Fieldset>
                    </div>
                </div>
            </div>
        )) || null
    }

}