export const changeState = (target, self, func) => {
  const getValue = () => {
    return (target.value === true && self.state[target.name] === true) ? false : target.value
  }
  let state = {};
  state[target.name] = getValue();
  self.setState(state, func);
}

export const guid = (base='f') => `${base}${(~~(Math.random()*1e8)).toString(16)}${(+new Date()).toString(16)}`;


export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : false;
}

export function deleteCookie( name, path, domain ) {
  if( getCookie( name ) ) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}


function o2D(d){
  d = d + '';
  return d.length === 1 ? '0' + d : d
}

export function unixToDate(UNIX_timestamp, withTime = true){

  if(Number.isNaN(UNIX_timestamp * 1)) return UNIX_timestamp;

  const a = new Date(UNIX_timestamp * 1);
  const year = a.getFullYear();
  const month = o2D ( (a.getMonth() + 1));
  const date = o2D ( a.getDate());
  if(withTime){
    const hour = o2D ( a.getHours());
    const min = o2D ( a.getMinutes());
    const sec = o2D ( a.getSeconds());
    return date + '.' + month + '.' + year + ' ' + hour + ':' + min + ':' + sec ;
  }
  return date + '.' + month + '.' + year;
}

export function getUTime(d) {
  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
}