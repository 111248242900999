import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import agent from './../../agent';
import { guid } from '../../utils/utils';


export default class ReportNetworks extends Component {

    constructor(){
        super();
        this.state = {networks: []};
    }

    loadNetworks(){
        agent.Network.list().then((networks)=>{
            this.setState({networks: networks})
        })
    }
    
    componentDidMount() {
       this.loadNetworks();
    }

    render() {
       
        const {networks} = this.state;
        return (
            <div className="p-grid">
                <div className="p-col-12"><h2>Выберите торговую сеть:</h2></div>
                {
                    networks.map(
                        network => (
                            <Link key={guid()} to={`/reports/${network.id}`} className="p-col-6 p-md-4">
                                <div className="report card">
                                    {network.name}
                                </div>
                            </Link>
                        )
                    )
                }
            </div>
        )    
    }
}