const fields2DB = [
    'surname',
    'name',
    'secondname',
    'mail',
    'userRoles',
    'password',
    'id'
]

export default class User{
    constructor(){
        this.surname = '';
        this.name = '';
        this.secondname = '';
        this.mail = '';
        this.isNew = true;
        this.roles = [];
        this.userRoles = [];
        this.password ='';

    }

    hasRole(name){
        let has = false;
        this.userRoles.forEach((r)=>{
            if(r.role.name === name) {
                has = true;
            }
        })
        return has;
    }

    set(user){
        return Object.assign(this, user);
    }
    
    isSuperAdmin(){
        return this.hasRole("superuser");
    }
    isAdmin(){
        return this.hasRole("administrator");
    }
    isCreator(){
        return this.hasRole("creator");
    }
    isViewer(){
        return this.hasRole("viewer");
    }
    isSubscriber(){
        return this.hasRole("mail");
    }
}

export const filter2DB = (fields) => {
    const result = {};
    Object.entries(fields).forEach(([key, value]) => {
        if(fields2DB.indexOf(key) > -1) result[key] = value;
    });
    return result;
}
