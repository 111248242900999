import {
  LOGIN,
  APP_LOAD,
  LOGIN_ERROR
} from '../constants/actionTypes';

export default (state = {authorized: false, authError: false, user: {}}, action) => {
  switch (action.type) {
    case APP_LOAD:
    case LOGIN:
      return {
        ...state,
        authorized: action.authorized,
        user: action.user,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        authorized: false,
        authError: true,
        user: {},
      };
    default:
      return state;
  }
};
