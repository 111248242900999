import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Agent } from '../Agent';
import { changeState, guid } from './../../utils/utils';
import { InputSwitch } from 'primereact/inputswitch';
import Network from '../../constants/types/network';
import agent from './../../agent';

export default class NetworkDialog extends Component {

    constructor(){
        super();
        this.state = {agentsValues: {}, ...(new Network())}
    }

    onAddAgent(){
        const agents = this.state.agents;
        const guidValue = guid();
        agents[guidValue] = {
            fio: '',
            mail: '',
            expert: false,
            subscriber: false,
            id: guidValue,
            onRemove: (key) => this.onRemoveAgent(key)
        }
        this.setState({agents});
    }
    
    loadNetwork(id){
        agent.Network.one(id).then((network)=>{
            network['isNew'] = false;
            this.setState((new Network()).set(network))
        })

        agent.Network.templates(id).then(templates => {
            const templatesChecked = templates.reduce((prev, curr) => {
                return prev.concat([curr.id]);
            }, []);
            const templatesData = this.state.templatesData.sort((a,b) => templatesChecked.indexOf(b.id) - templatesChecked.indexOf(a.id))
            this.setState({templatesChecked, templates, templatesData})
        })
    }
    
    loadUsers(){
        agent.User.list().then(users => {
            const usersData = users.reduce((prev, curr) => {
                return prev.concat([Object.assign(curr, {label: curr.surname + ' ' + curr.name + ' ' + curr.secondname, value: curr.id})]);
            }, [{label: 'Новый пользователь', value: 0}]);
            this.setState({users: usersData})
        })
        agent.User.rolesList().then(roles => {
            roles = roles.filter(a => a.id !== 1 )
            this.setState({roles})
        })
    }
    addAgentRoles(user, roles, network){
        roles.map(
            role => agent.User.roles(user, {role: {id: role}, tradeNetwork: {id: network}}).then(()=>{})
        )
    }
    onCreate(){
        const {templatesChecked, agentsValues} = JSON.parse(JSON.stringify(this.state));
        const network = this.state.filter2DB(this.state);
        agent.Network.create(network).then(
            (data) => {
                Object.entries(agentsValues).forEach(([key, value])=>{
                    if(value.user > 0 ){
                        this.addAgentRoles(value.user, value.roles, data.id)
                    } else {
                        if(value.mail.length > 0 && value.fio.length > 0){
                            const fio = value.fio.split(' ');
                            const user  = {
                                surname: fio[0] || '',
                                name: fio[1] || '',
                                secondname: fio[2] || '',
                                mail: value.mail,
                                password: guid('pass')
                            };
                            agent.User.create(user).then(user => this.addAgentRoles(user.id, value.roles, data.id))
                        }
                    }                    
                })
                this.props.onUpdate(templatesChecked, data.id);
            }
        )
    }
    onUpdate(){
        const {templatesChecked, id} = JSON.parse(JSON.stringify(this.state));
        const property = this.state.filter2DB(this.state);
        agent.Network.update(property.id, property).then(
            () => {
                this.props.onUpdate(templatesChecked, id);
            }
        )
    }

    onDelete(){
        if(window.confirm(`Действительно удаляем ${this.state.name}`)){
            agent.Network.delete(this.state.id).then(
                () => this.props.onUpdate()
            )
        }
    }

    componentDidMount(){
        if(this.props.network !== 0){
            this.loadNetwork(this.props.network);
        }        
        this.setState({templatesData: this.props.templatesData});
        this.loadUsers();
    }

    onRemoveAgent(key){
        const {agents, agentsValues} = this.state;
        delete agentsValues[key]
        delete agents[key];
        this.setState({agents, agentsValues});
    }   
    onChangeState(target){
        changeState(target, this);
    }

    onCheckTemplates(e, key){
        let {templatesChecked} = this.state;
        if(e.value){
            templatesChecked.push(key)
        } else {
            templatesChecked = templatesChecked.filter(a => a !== key)
        }
        this.setState({templatesChecked})
    }

    onChangeAgent(agent){
        const agentsValues = this.state.agentsValues;
        agentsValues[agent.id] = agent;
        this.setState({agentsValues});
    }
    
    render () {
    
        const {onHide} = this.props;
        const {agents, name, isNew, id, templatesData, templatesChecked, seeAll, users, roles} = this.state;

        const footer = (
            <div className="p-grid modal-form">
               <div className="p-col-6" style={{textAlign: "left"}}>
                   { ( !isNew && id > 0 )&& <Button className="p-button-danger" label="Удалить сеть" icon="pi pi-trash" onClick={() => this.onDelete()}/>}
               </div>
               <div className="p-col-6">
                   {id > -1 && <Button label="Сохранить" icon="pi pi-check" onClick={() => (id > 0 ? this.onUpdate() : this.onCreate())} />}
               </div>
           </div>
       );
        return (
            <Dialog visible={true} style={{width: '70vw'}} header="Торговая сеть" footer={footer} modal={true} onHide={()=>onHide()}>
                <div className="p-grid modal-form">
                    <div className="p-col-12">
                        <Fieldset legend="Основное">
                            <InputText defaultValue = {name} name = "name" style={{maxWidth: '400px'}} onChange = {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Название торговой сети" />                           
                        </Fieldset>
                    </div>
                    {isNew && <div className="p-col-12">
                        <Fieldset legend="Представители">
                            <div className="p-grid">
                                {Object.entries(agents).map(([key, agent]) => <Agent  roles = {roles} onChange = {agent => this.onChangeAgent(agent)} key={key} users={users} {...agent}/>)}
                                <div className="p-col-12">
                                    <Button onClick={()=>this.onAddAgent()} label="Добавить представителя"></Button>
                                </div>
                            </div>
                        </Fieldset>
                    </div>}
                    {templatesData && <div className="p-col-12">
                        <Fieldset legend="Шаблоны">
                        <div className="p-grid switches">
                                {
                                    templatesData.map( (value, key) => (
                                        <div className="p-col-6" key={key} style = {{display: !seeAll && key > 5 ? 'none':''}}>
                                            <InputSwitch style={{minWidth: '40px'}} checked={templatesChecked.indexOf(value.id) > -1} onChange={(e) => this.onCheckTemplates(e, value.id)} />
                                            <span>{value.name}</span>
                                        </div>
                                    ))
                                }
                                <div className="p-col-12">
                                {(templatesData.length > 6 && !seeAll) && <Button onClick={()=>this.setState({seeAll: true})} label="Больше"></Button>}
                                </div>
                            </div>
                        </Fieldset>
                    </div>}
                </div>
            </Dialog>
        )    
    }

}