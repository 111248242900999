import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { store} from './store';

import { HashRouter } from 'react-router-dom';

import App from './App';


ReactDOM.render((
    <Provider store={store}>
        <HashRouter>
          <App></App>
        </HashRouter>,
    </Provider>
  
  ), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();