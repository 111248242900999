
import React from 'react';

export default class Splash extends React.Component {
    render() {
        return <div className="splash-screen">
            <div className="splash-container">
                <div className="splash-double-bounce1"></div>
                <div className="splash-double-bounce2"></div>
            </div>
        </div>
    }
}

