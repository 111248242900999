import React, { Component } from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { unixToDate, guid } from '../../utils/utils';
import { Link } from 'react-router-dom';
import User from '../../constants/types/user';

const mapStateToProps = state => {
    return {
      user: state.auth.user,
      typePage: state.reports.pageType
    }
};
const mapDispatchToProps = {};


class Report extends Component {

    constructor(){
        super();
        this.state = {
            report: {}, 
            columnsData: {},
            hasNames: [],
            generateLinkText: 'Создать файл отчёта',
            items: [
                {label:'Отчеты', url: '/#/reports'},
                {label:'', url: '/#/reports/$1'},
                {label:'', url: '/#/reports/$1/$2/$3'},
                {label:''},
            ],
        }
    }

    componentDidMount(){
        this.reportId = this.props.match.params.id;
        const {items, hasNames} = this.state;
        const columnsData = {}, columnsTypes={};
        const {user} = this.props;
        const userObj = (new User()).set(user);

        const typePage = this.props.typePage;

        agent.Report.one(this.reportId).then(report => 
            {           
                items[2].url = items[2].url.replace('$2', this.props.typePage);
                if(!report) {
                    return this.setState({notfound:true})
                }
                report && report.columns.forEach(column => {
                    if(column.id === 'network'){
                        items[1].url = items[1].url.replace('$1', column.value)
                        items[2].url = items[2].url.replace('$1', column.value)
                    }

                    if(column.id === 'network_name'){
                        items[1].label = column.value
                    }

                    if(column.id === 'market' && typePage === 'markets'){
                        items[2].url = items[2].url.replace('$3', column.value)
                    }

                    if(column.id === 'template' && typePage === 'templates'){
                        items[2].url = items[2].url.replace('$3', column.value)
                    }

                    if(column.id === 'market_name' && typePage === 'markets'){
                        items[2].label = column.value
                    }

                    if(column.id === 'template_name' && typePage === 'templates'){
                        items[2].label = column.value
                    }

                    const hasName = report.columns.find((el) => {
                            return el.id === column.id + '_name'
                        });
                    if(hasName){hasNames.push(column.id)}

                    report.allFields.forEach(v => {
                        columnsData[v.id] = v.columnName
                        columnsTypes[v.id] = v.type
                    })

                    items[3].label = 'Отчет #' + report.id
                })
                report.columns = report.columns.reverse();
                this.setState({report, items, hasNames, columnsData, columnsTypes, isAdmin: userObj.isAdmin()})
            }
        );   
    }
    
    onDownload(){
        window.location.assign(this.state.downloadLink);
    }

    onCreateReportLink(){
        const {report} = this.state;
        this.setState({generateLinkText: 'Генерация ссылки'});
        agent.Report.download(report.id).then((data)=>{
            this.setState({downloadLink: data.link.replace('http://', 'https://')}, ()=>this.onDownload());        
        })
    }
    
    onDeleteReport(){
        console.log(this.state.report);
        if(window.confirm(`Действительно удаляем отчёт #${this.reportId}`)){
            agent.Report.delete(this.reportId).then(()=>{
                this.setState({removed: true});
            })
        }
    }

    getResult(type, value){
        if(type === 'DATE'){
            return <span>{ unixToDate(value)}</span>;    
        }
        return <span>{value}</span>;
    }

    render = () => {
        const {report, items, columnsData, columnsTypes, hasNames, downloadLink, generateLinkText, isAdmin, removed, notfound} = this.state;
        const home = {icon: 'pi pi-home', url: '/#/reports'}
        if(notfound){
            return <h1> Отчет не найден</h1>
        }
        return (report.columns && (<div>
            <BreadCrumb className="p-col-12" model={items} home={home}/>
            
            {
                removed && 
                <div className="report card">
                    <h3>Отчет #{report.id} удален. <p><Link to={items[2].url.replace('/#', '')}>Перейти в раздел {items[2].label}</Link></p></h3>
                </div>
            }
            {
            !removed && <div className="report card">
                <div className="p-grid">
                    <div className="p-col"><h2>Отчет #{report.id}</h2></div>
                    
                    <div className="p-col" style={{textAlign: "right"}}>
                        {downloadLink && <a target="_blank" rel="noopener noreferrer" href={downloadLink} className="p-button p-component p-button-success p-button-text-icon-left">
                            <span className="pi pi-save p-c p-button-icon-left"></span>
                            <span className="p-button-text p-c">Скачать</span>
                        </a>}

                        {!downloadLink && <button onClick={()=>{this.onCreateReportLink()}}className="p-button p-component p-button-success p-button-text-icon-left">
                            <span className="pi pi-cloud-download p-c p-button-icon-left"></span>
                            <span className="p-button-text p-c">{generateLinkText}</span>
                        </button>}
                    </div>
                </div>
                <hr/>
                <div className="p-grid">
                    {
                        report.columns.map(column => {return (
                            hasNames.indexOf(column.id) === -1 &&
                                <div key={guid()} className="p-col-12 p-md-4">
                                    <b>{columnsData[column.id] ? columnsData[column.id] : columnsData[column.id.replace('_name', '')]}</b>: {this.getResult(columnsTypes[column.id], column.value) }
                                </div>
                            ) || null}
                        )
                    }
                </div>
                { report.files.length > 0 && <hr/> }
                {
                    report.files.map(file => (
                        <p key={guid()}>
                            <a target="_blank" rel="noopener noreferrer" href={`${agent.Files.url()}/${file.id}`}>{file.name}</a>
                        </p>
                    ))
                }
                <div className="p-col" style={{textAlign: 'right'}}>
                    {isAdmin && <button onClick={()=>{this.onDeleteReport()}}className="p-button p-component p-button-danger p-button-text-icon-left">
                        <span className="pi pi-trash p-c p-button-icon-left"></span>
                        <span className="p-button-text p-c">Удалить отчёт</span>
                    </button>}
                </div>
            </div>
            }
        </div>)) || null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
