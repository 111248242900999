import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import NetworkDialog from './NetworkDialog';
import agent from './../../agent';


export class Networks extends Component {

    constructor(){
        super();
        this.state = {networks: [], dialog: false, network: 0};
    }

    loadNetworks(){
        agent.Template.list().then((templates) => {
            this.setState({templatesData: templates.sort((a,b)=>b.id-a.id)})
        })
        agent.Network.list().then((networks)=>{
            this.setState({networks: networks, dialog: false})
        })
    }
    
    componentDidMount() {
       this.loadNetworks();
    }

    showDialog(e){
        if(e){
            this.setState({dialog: true, network: e.data.id})
        } else{
            this.setState({dialog: true, network: 0})
        }
    }

    onUpdateNetworks(templates, network){
        if(templates && network){
            templates.forEach(template_id => {
                agent.Network.template(network, template_id).then();
            });
        }
        this.loadNetworks();
    }

    render() {
    
        const {network, dialog, templatesData} = this.state;
        const action = (rowData) => {
            return <div>
                

                <Link to={`/networks/${rowData.id}`} >
                    <Button type="button" icon="pi pi-shopping-cart" className="p-button-default"></Button>
                </Link>
                {' '}
                <Button type="button" icon="pi pi-pencil" onClick={()=>this.showDialog({data:rowData})} className="p-button-warning"></Button>
            </div>;
        }

        const header = <div className="p-grid">
                        <div style={{'textAlign':'right'}} className="p-col-12">
                            <Button onClick = { () => this.showDialog() } label="Добавить торговую сеть"></Button>
                        </div>
                    </div>;
        
        return <Card>
                <Fieldset legend="Торговые сети">
                    <DataTable value={this.state.networks} 
                        style={{marginBottom: '20px'}} 
                        responsive={true}
                        selectionMode="single" 
                        onSelectionChange={(e) => this.setState({network: e.value.id})} 
                        paginator={true} 
                        rows={10} 
                        header={header} 
                        emptyMessage="Нет ТС с такими параметрами"
                    >
                        <Column style={{width: '80px', textAlign: 'center'}} field="id" header="#" filter = {true} sortable={true} />
                        <Column field="name" header="Название" filter = {true} sortable={true} />
                        <Column body={action} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                </Fieldset> 
                {dialog && <NetworkDialog templatesData={templatesData} network={network} onUpdate = {(templates, id) => this.onUpdateNetworks(templates, id)} visible = {true} onHide = {() => this.setState({dialog: false})}/>}
            </Card>
    }
}