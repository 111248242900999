import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import PropertyDialog from './PropertyDialog';
import agent from './../../agent';

export class Lists extends Component {

    constructor(){
        super();
        this.state = {properties: [], dialog: false, property: 0};
    }

    loadProperties(){
        agent.Property.list().then((properties) => {
            this.setState({properties: properties, dialog: false})
        })
    }

    componentDidMount(){
        this.loadProperties();
    }

    showDialog(e){
        if(e){
            this.setState({dialog: true, property: e.data.id})
        } else{
            this.setState({dialog: true, property: 0})
        }
    }

    onUpdateProperties(){
        this.loadProperties();
    }

    render() {
        const header = <div className="p-grid">
                        <div style={{'textAlign':'left'}} className="p-col-9">
                            <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Поиск по всем полям" size="50"/>
                            </div>
                        <div style={{'textAlign':'right'}} className="p-col-3">
                            <Button onClick = { () => this.showDialog() } label="Добавить список"></Button>
                        </div>
                    </div>;

        return <Card>
                <Fieldset legend="Шаблоны">
                    <DataTable value={this.state.properties} 
                        style={{marginBottom: '20px'}} 
                        responsive={true} 
                        selectionMode="single" 
                        selection={this.state.template}
                        onRowSelect={(e)=>this.showDialog(e)}
                        onSelectionChange={(e) => this.setState({property: e.value.id})} 
                        paginator={true} 
                        rows={10} 
                        header={header} 
                        globalFilter={this.state.globalFilter} 
                        emptyMessage="Не найдено"
                    >
                        <Column style={{width: '80px', textAlign: 'center'}} field="id" header="#" filter = {true} sortable={true} />
                        <Column field="name" header="Название списка" filter = {true} sortable={true} />
                    </DataTable>
                </Fieldset> 
                {this.state.dialog && <PropertyDialog  onUpdate = {() => this.onUpdateProperties()} property = {this.state.property} visible = {true} onHide = {() => this.setState({dialog: false})}/>}
            </Card>
    }
}