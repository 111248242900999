import React, {Component} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppProfile} from './AppProfile';
import {Route} from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';
import agent from './agent';
import { store } from './store';
import { APP_LOAD } from './constants/actionTypes'
import { getCookie } from './utils/utils'
import LoginPage from './constants/login';

import Report from './components/Expert/Report';
import ReportCreate from './components/Expert/ReportCreate';
import ReportNetworks from './components/Expert/Networks';
import ReportsCategories from './components/Expert/ReportsCategories';

import { Users } from './components/Users/Users';
import { Profile } from './components/Users/Profile';
import { Networks } from './components/Networks/Networks';
import { Templates } from './components/Templates/Templates';
import { Lists } from './components/Lists/Lists';
import { Markets } from './components/Markets/Markets';
import User from './constants/types/user';
import ReportBy from './components/Expert/ReportBy';

import Rules from './components/Rules/Rules';
import Rule from './components/Rules/Rule';


const mapStateToProps = state => {
    return {
      authorized: state.auth.authorized,
      user: state.auth.user
    }};
  
  const mapDispatchToProps = dispatch => ({
    onLoad: (authorized, user) =>
      dispatch({ type: APP_LOAD, authorized: authorized, user: user}),
  });
  

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            isLoggedIn: false,
            url: '/'
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
    }

    onLogin(){
        this.setState({isLoggedIn: true})
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu(user) {
        const u = (new User()).set(user);
        
        const menu = [];

        if(u.isAdmin() || u.isSuperAdmin()){
            menu.push({
                label: 'Администратор', icon: 'pi pi-fw pi-desktop',
                items: [
					{label: 'Пользователи', icon: 'pi pi-fw pi-user', to: '/users'},
					{label: 'Шаблоны отчетов', icon: 'pi pi-fw pi-file', to: '/templates'},
					{label: 'Торговые сети', icon: 'pi pi-fw pi-th-large', to: '/networks'},
                    {label: 'Списки характеристик', icon: 'pi pi-fw pi-list', to: '/lists'},
                    {label: 'Правила рассылки', icon: 'pi pi-fw pi-envelope', to: '/rules'},
                ]
            })
        }
        if(u.isViewer() || u.isSuperAdmin()){
            menu.push({label: 'Отчеты', icon: 'pi pi-fw pi-file-o', to: '/reports'})
        }

        if(u.isCreator() || u.isSuperAdmin()){
            menu.push({label: 'Создать отчёт', icon: 'pi pi-fw pi-check', to: '/new-report'})
        }

        return menu;
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    componentDidMount(){
        if(!store.getState().auth.authorized){
            try{
                const user = atob(getCookie('user'));
                const pass = atob(getCookie('pass'));
                
                if (user && pass) {
                    
                    agent.setToken({email: user, password: pass});
                    
                    agent.User.profile()
                    .then (
                        userData => {
                            document.cookie = `user=${btoa(user)}; max-age=900`;
                            document.cookie = `pass=${btoa(pass)}; max-age=900`;
                            this.props.onLoad(true, userData)
                        }
                    )
                    .catch (
                        () => this.props.onLoad(false, {})
                    )   
                }
            }
            catch (e){
                console.error('need auth', e);
            }
        }

        window.addEventListener('hashchange', (e)=>{ this.setState({url: e.newURL})});
    }
    
    render() {
        const {user} = store.getState().auth;
    
        if(!store.getState().auth.authorized){
            return <div>
                    <LoginPage/>
                </div>
        }

        const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo.png': 'assets/layout/images/logo.svg';

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        return (
            <div>
                <div className={ wrapperClass } onClick={ this.onWrapperClick }>
                        <AppTopbar onToggleMenu={this.onToggleMenu}/>

                        <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                            <div className="layout-logo">
                                <img alt="Logo" src={logo} />
                            </div>
                            <AppProfile />
                            {user && <AppMenu model={this.createMenu(user)} onMenuItemClick={this.onMenuItemClick} />}
                        </div>
                        {this.state.url && <div className="layout-main">
                            <Route path="/" exact component={ReportNetworks} />
                            <Route path="/users" component={Users} />
                            <Route path="/templates" component={Templates} />
                            <Route path="/lists" component={Lists} />

                            <Route path="/networks/:id" component={Markets} />
                            <Route path="/networks" exact component={Networks} />

                            <Route path="/profile" exact component={Profile} />

                            <Route path="/reports/:network/templates/:template" exact render={(props) => <ReportBy {...props}/>} />
                            <Route path="/reports/:network/markets/:market" exact render={(props) => <ReportBy {...props}/>} />
                            <Route path="/reports/:network/:type" exact component={ReportsCategories} />         
                            <Route path="/reports/:network" exact component={ReportsCategories} />                            
                            <Route path="/reports" exact component={ReportNetworks} />

                            <Route path="/report/:id" exact render={(props) => <Report {...props}/>} />
                            
                            <Route path="/new-report/:network/:market/:template/" exact render={(props) => <ReportCreate {...props}/>} />
                            <Route path="/new-report/:network/:market" exact render={(props) => <ReportCreate {...props}/>} />
                            <Route path="/new-report/:network" exact render={(props) => <ReportCreate {...props}/>} />
                            <Route path="/new-report" exact render={(props) => <ReportCreate {...props}/>} />

                            <Route path="/rules" exact render={(props) => <Rules {...props}/>} />
                            <Route path="/rules/:id" render={(props) => <Rule {...props}/>} />
                        </div>}

                        <AppFooter />

                        <div className="layout-mask"></div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

