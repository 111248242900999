import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { changeState } from '../utils/utils';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export class Agent extends Component {
    
    constructor(props){
        super();
        this.state = {...props, user: 0};
    }

    agentData(state){
        const roles = Object.keys(this.state).filter(a => a.indexOf('role_') === 0).reduce((prev, curr) => prev.concat([curr.replace('role_', '')]), []);
        return {
            id: state.id,
            user: state.user, 
            fio: state.fio, 
            mail: state.mail, 
            roles: roles
        }
    }

    onChangeState(target){
        changeState(target, this, () => this.props.onChange(this.agentData(this.state)));
    }

    render(){
        const {fio, mail, user, id, roles} = this.state;

        return (
            <div className="p-lg-4 p-sm-12 p-md-6">
                <div className = "agent">
                
                    <Dropdown name="user" value={user} options={this.props.users} onChange={(e) => {this.onChangeState(e.target)}} placeholder="Выбрать пользователя" filter={true} filterBy="label"/>

                    {
                        this.state.user === 0 && (
                            <div>
                                <InputText value = {fio} name = "fio" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="ФИО" />
                                <InputText value = {mail} name = "mail" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="email" />
                            </div>
                        )
                    }
                    {
                        roles.map(role => <div className="p-col-12" key={role.id}>
                                <Checkbox inputId={'role_' + role.id} name = {'role_' + role.id}  value={true} onChange={e => this.onChangeState(e.target)} checked={this.state['role_' + role.id]}></Checkbox>
                                <label htmlFor={'role_' + role.id} className="p-checkbox-label">{role.description}</label>
                            </div>
                        )
                    }
                    <div style={{textAlign: 'right'}} className="p-col-12">
                        <Button icon="pi pi-trash" className = "p-button-danger" label = 'Удалить' onClick={() => this.props.onRemove(id)}/>
                    </div>
                </div>
            </div>
        )
    }
}