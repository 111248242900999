import React, { Component } from 'react';

import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { changeState, guid, getUTime } from '../../utils/utils';

import Splash from '../Splash';
import agent from '../../agent';


export default class ReportCreate extends Component {

    constructor(){
        super();
        this.state = this.getInitialState();
    }

    getInitialState(){
        return {files:[], fields: {}, errors: [], saved: false}
    }
    onUpload(value){
        this.setState({files: this.state.files.concat(value)});
    }

    onChangeState(target){
        const errors = this.state.errors.filter(a => a !== target.name);
        this.setState({errors});
        changeState(target, this);
    }

    loadTemplate(id){
        const state = Object.keys(this.state).reduce((object, key) => {
            if (key.indexOf("$.") === -1) {
                object[key] = this.state[key]
            } else {
                object[key] = undefined;
            }
            return object
          }, {})

        state['required'] = ['check_date'];

        agent.Template.one(id).then((template)=>{
            const fields = {};
            template.templateFields.forEach(field => {
                if(!fields[field.templateFieldType.type]) {
                    fields[field.templateFieldType.type] = [];
                }
                fields[field.templateFieldType.type].push(field);

                if(field.templateFieldType.type === 'BOOLEAN'){
                    state[`$.${field.templateFieldType.type}.` + field.id] = false;
                } else {
                    state[`$.${field.templateFieldType.type}.` + field.id] = '';
                }

                if(field.required) state['required'].push(`$.${field.templateFieldType.type}.` + field.id);

                if(field.templateFieldType.type === 'LIST') {
                    agent.Property.one(field.listId).then(resultsData => {
                        const list = resultsData.listValues.reduce((prev, curr) => {
                            return prev.concat([{value: curr.id, label: curr.name}]);
                        }, []);
                        
                        const lists = this.state.lists || {};
                        lists[field.id] = list;
                        this.setState({lists})
                    })
                }

            });
            state.fields = fields;
            state.template = id;
            this.setState(state);
        })
    }

    loadMarkets(id, market=false){
        agent.Market.list(id).then(marketsData => {
            const markets = marketsData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name, city: curr.city}]);
            }, []);
            this.setState({markets, marketsData, network: id, market: market, template: false})
        })

        agent.Network.templates(id).then(templatesData => {
            const templates = templatesData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name}]);
            }, []);
            this.setState({templates})
        })
    }

    loadNetworks(){
        agent.Network.list().then(networksData => {
            const networks = networksData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name}]);
            }, []);
            this.setState({networks})
        })

        agent.Property.one(-1).then(resultsData => {
            const results = resultsData.listValues.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name}]);
            }, []);
            this.setState({results, result: results[0].value})
        })
    }

    onDeleteFile(id){
        agent.Files.delete(id).then(() => {
            this.setState({files: this.state.files.filter(a => a.id !== id)});
        })
    }
    
    save(){
        
        const {files, template, network, check_date, market, result, marketsData, lists} = this.state;
        const report = {columns: [], files: files};
        const currMarket = marketsData.find(a => a.id === market);
        const errors = [];
        const rawKeys = Object.keys(this.state).map(key => key).filter(a => a.indexOf('$') === 0);

        rawKeys.forEach(key=>{
            if(this.state[key] !== undefined){
                if( this.state[key] !== ''){
                    if(key.indexOf('$.') > -1){
                        const arrKey = key.split('.');
                        if(arrKey[1] === "DATE"){
                            report.columns.push({id: arrKey[2], value: getUTime(this.state[key])})
                        } else if(arrKey[1] === "BOOLEAN"){
                            report.columns.push({id: arrKey[2], value: this.state[key] === true ? '1' : '0'})
                        } else if(arrKey[1] === "LIST"){
                            report.columns.push({id: arrKey[2], value: lists[arrKey[2]].find(a => a.value === this.state[key]).label})
                        } else {
                            report.columns.push({id: arrKey[2], value: this.state[key] + ''})
                        }
                    } else {
                        report.columns[key.replace('$','')] = this.state[key]
                    }
                } else {
                    if(this.state.required.indexOf(key) > -1){
                        errors.push(key);
                    }
                }
            }
        })
        if(errors.length > 0){
            this.setState({errors})
        } else {
            report.columns.push({id:'template', value: template + ''})
            report.columns.push({id:'network', value: network + ''})
            report.columns.push({id:'market', value: market + ''})
            report.columns.push({id:'result', value: result + ''})
            report.columns.push({id:'check_date', value: getUTime(check_date)})
            report.columns.push({id:'city', value: currMarket.city + ''})
            
            agent.Report.create(report).then(
               (result) => {
                   this.setState({saved: result.id})
                }
            )
        }
    }

    componentDidMount(){

        let {
            network,
            market,
            template
        } = this.props.match.params;

        if(typeof market !== 'undefined'){
            market = Number.parseInt(market);
        }

        if(typeof network !== 'undefined'){
            network = Number.parseInt(network);
            if(network > 0) this.loadMarkets(network, market || false);
        }

        if(typeof template !== 'undefined'){
            template = Number.parseInt(template);
            if(template > 0) this.loadTemplate(template);
        }

        this.setState({
            market
        }, this.loadNetworks());
    }

    clear(){
        setTimeout(()=>{window.location.reload()}, 100);
    }
    
    templateItem(option) {
        if (!option.value) {
            return option.label;
        }
        else {    
            return (
                <div className="p-clearfix">
                    <span>{option.label}</span>
                    <p style={{fontSize: '10px', marginTop: '4px', marginBottom: '0px'}}>{option.city}</p>
                </div>
            );
        }
    }

    render = () => {
        const {files, networks, markets, templates, template, network, market, results, fields, lists, errors, saved, required} = this.state;

        if(saved){
            return <div className="p-grid">
                <div className="p-col-12" style={{textAlign: "center"}}>    
                    <p></p>
                    <h2>Готово!</h2>            
                    <p><Link to={`/report/${saved}`}>Просмотреть созданный отчёт</Link></p>
                    или
                    <p>
                        <Link onClick = {() => this.clear()} to={`/new-report/${network}/${market}/${template}?${guid()}`}>
                            <Button icon="pi pi-check" label="Создать новый" className="p-button-primary"></Button>
                        </Link>
                    </p>
                </div>
            </div>
        }

        return (networks && 
        <div className="p-grid">
            <h2 className="p-col-12">Создание отчёта</h2>
            <div className="p-col-12 report card">
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <Dropdown 
                            className="p-col-12" 
                            name="$network" 
                            defaultValue={network} 
                            value={network} 
                            options={networks} 
                            onChange={(e) => this.loadMarkets(e.target.value)}  
                            placeholder="Выбрать торговую сеть" 
                            filter={true} 
                            filterBy="label"
                            />
                    </div>
                    {markets && <div className="p-col-12 p-md-4">
                            <Dropdown 
                                className="p-col-12" 
                                name="$market" 
                                defaultValue={market} 
                                itemTemplate={this.templateItem} 
                                value={market} options={markets} 
                                onChange={(e) => this.setState({market: e.target.value})}  
                                placeholder="Выбрать гипермаркет" 
                                filter={true} 
                                filterBy="label"
                            />
                        </div>
                    }
                    {templates && <div className="p-col-12 p-md-4">
                            <Dropdown 
                            className="p-col-12" 
                                name="$template" 
                                defaultValue={template} 
                                value={template} options={templates} 
                                onChange={(e) => this.loadTemplate(e.target.value)}  
                                placeholder="Выбрать шаблон" 
                                filter={true} 
                                filterBy="label"
                            />
                        </div>
                    }
                </div>
                {
                    (!!network && !!market && !!template) && (
                        <div> 
                            <div className="p-grid p-fluid switches switches_top">    
                                {results && <div className="p-col-12 p-md-4"><Dropdown className="p-col-12"  name = "result" onChange= {e => this.onChangeState(e.target)}  value={this.state['result']} options={results}/></div>}
                                <div className="p-col-12 p-md-4">
                                    <span className="input-label">
                                        {!!this.state['check_date'] ? 'Дата отбора' : ''}
                                    </span>
                                    <Calendar 
                                        name = 'check_date'
                                        onChange = {e => this.onChangeState(e.target)} 
                                        value = {this.state['check_date']} 
                                        placeholder='Дата отбора' 
                                    />
                                </div>
                            </div>
                            {Object.entries(fields).map(([key, fieldsData]) => 
                                <div className="p-grid p-fluid switches switches_top" key={key}>  
                                 
                                    {
                                        fieldsData.map((field) => {
                                            if(key === "LIST"){
                                                return <div key={'key.custom.' + field.id} className={"p-col-12 p-md-4" + (errors.indexOf(`$.${key}.` + field.id) > -1 ? ' error-field' : '') + (required.indexOf(`$.${key}.` + field.id) > -1 ? ' req' : '')}>
                                                    {
                                                        lists && <Dropdown 
                                                            className = "p-col-12"
                                                            name = {`$.${key}.` + field.id} 
                                                            onChange = {e => this.onChangeState(e.target)}
                                                            value={this.state[`$.${key}.` + field.id]}
                                                            placeholder={field.name}
                                                            options={lists[field.id]}
                                                        />
                                                    }
                                                    
                                                </div>}
                                            else if(key === "DATE"){
                                                return <div key={'key.custom.' + field.id} className={"p-col-12 p-md-4" + (errors.indexOf(`$.${key}.` + field.id) > -1 ? ' error-field' : '' + (required.indexOf(`$.${key}.` + field.id) > -1 ? ' req' : ''))}>
                                                    <span className="input-label">
                                                        {this.state[`$.${key}.` + field.id] !=='' ? field.name : ''} 
                                                    </span>
                                                    <Calendar 
                                                        name = {`$.${key}.` + field.id} 
                                                        onChange = {e => this.onChangeState(e.target)} 
                                                        value = {this.state[`$.${key}.` + field.id]} 
                                                        placeholder={field.name} 
                                                    />
                                                </div>}
                                            else if(key === "BOOLEAN"){
                                                return <div key={'key.custom.' + field.id} className={"p-col-12 p-md-4" + (errors.indexOf(`$.${key}.` + field.id) > -1 ? ' error-field' : '') + (required.indexOf(`$.${key}.` + field.id) > -1 ? ' req' : '')}>
                                                    <InputSwitch 
                                                        name = {`$.${key}.` + field.id} 
                                                        onChange= {e => this.onChangeState(e.target)}  
                                                        checked = {this.state[`$.${key}.` + field.id]}
                                                    />
                                                    <span>{field.name}</span>
                                                </div>}
                                            else return <div key={'key.custom.' + field.id} className={"p-col-12 p-md-4" + (errors.indexOf(`$.${key}.` + field.id) > -1 ? ' error-field' : '') + (required.indexOf(`$.${key}.` + field.id) > -1 ? ' req' : '')}>
                                                    <span className="input-label">
                                                        {this.state[`$.${key}.` + field.id].length > 0 ? field.name : ''} 
                                                    </span>
                                                    <InputText 
                                                        className = "p-col-12"
                                                        name = {`$.${key}.` + field.id} 
                                                        onChange = {e => this.onChangeState(e.target)}  
                                                        value = {this.state[`$.${key}.` + field.id]} 
                                                        placeholder = {field.name} 
                                                    />
                                                </div>
                                        }                                       
                                    )}
                                </div>
                            )}
                        </div>
                    )

                    
                }
                {(!!network && !!market && !!template) && (
                    <div className="file-upload">
                        <FileUpload name="file" 
                            onBeforeSend={(event) =>{event.xhr.setRequestHeader("Authorization", agent.getToken())}} 
                            chooseLabel="Добавить файлы" 
                            url={`${agent.Files.postUrl()}`} 
                            auto={true} 
                            multiple={true} 
                            onUpload = {(data) => this.onUpload(JSON.parse(data.xhr.response))}
                        />
                        <div className="p-grid">
                            {files.map((file, key)=>(
                                <div key={key} className="p-col-12">
                                    <Button icon="pi pi-trash" className="p-button-danger" onClick={()=>this.onDeleteFile(file.id) } ></Button>&nbsp;&nbsp;<a target="_blank" rel="noopener noreferrer" href={`${agent.Files.url()}/${file.id}`}>{file.name}</a>
                                </div>
                            ))}
                        </div>
                    </div>
                    )
                }
                {(!!network && !!market && !!template) && (
                    <div style={{marginTop: '2em'}}>
                        <Button icon="pi pi-save" className="p-button-success" onClick={() => this.save()} label="Сохранить"></Button>
                    </div>
                    )
                }
            </div>       
        </div>) || <Splash></Splash>
    }
}