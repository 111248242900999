import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Fieldset} from 'primereact/fieldset';
import {Card} from 'primereact/card';
import { Button } from 'primereact/button';
import TemplateDialog from './TemplateDialog';
import agent from './../../agent';

export class Templates extends Component {

    constructor(){
        super();
        this.state = {templates: [], dialog: false, template: 0};
    }

    loadTemplates(){
        agent.Template.list().then((templates)=>{
            this.setState({templates: templates, dialog: false})
        })
    }

    onUpdateTemplates(){
        this.loadTemplates();
    }

    componentDidMount(){
        this.loadTemplates()
    }

    showDialog(e){
        if(e){
            this.setState({dialog: true, template: e.data.id})
        } else{
            this.setState({dialog: true, template: 0})
        }
    }

    render() {
        const header = <div className="p-grid">
                        <div style={{'textAlign':'left'}} className="p-col-9">
                            <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Поиск по всем полям" size="50"/>
                            </div>
                        <div style={{'textAlign':'right'}} className="p-col-3">
                            <Button onClick = { () => this.setState({dialog: true}) } label="Добавить шаблон"></Button>
                        </div>
                    </div>;

        return <Card>
                <Fieldset legend="Шаблоны">
                    <DataTable value={this.state.templates} 
                        style={{marginBottom: '20px'}} 
                        responsive={true} 
                        selectionMode="single" 
                        selection={this.state.template} 
                        onRowSelect={(e)=>this.showDialog(e)}
                        onSelectionChange={(e) => this.setState({template: e.value.id})} 
                        paginator={true} 
                        rows={20} 
                        header={header} 
                        globalFilter={this.state.globalFilter} 
                        emptyMessage="Не найдено шаблонов"
                    >
                        <Column style={{width: '80px', textAlign: 'center'}} field="id" header="#" filter = {true} sortable={true} />
                        <Column field="name" header="Название шаблона" filter = {true} sortable={true} />
                    </DataTable>
                </Fieldset> 
                {this.state.dialog && <TemplateDialog onUpdate = {()=>this.onUpdateTemplates()} visible = {true} template = {this.state.template} onHide = {() => this.setState({template: 0, dialog: false})}/>}
            </Card>
    }
}