import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Fieldset} from 'primereact/fieldset';
import {Card} from 'primereact/card';
import { Button } from 'primereact/button';
import agent from './../../agent';
import { Redirect } from 'react-router-dom';

export default class Rules extends Component {

    constructor(){
        super();
        this.state = {templates: [], dialog: false, template: 0};
    }

    loadRules(){
        agent.Rules.list().then((templates)=>{
            this.setState({templates: templates, dialog: false})
        })
    }

    onUpdateRules(){
        this.loadRules();
    }

    componentDidMount(){
        this.loadRules()
    }

    showDialog(e){
        if(e){
            this.setState({dialog: true, template: e.data.id})
        } else{
            this.setState({dialog: true, template: 0})
        }
    }

    render() {

        if(this.state.redirect) {
            window.history.pushState({}, document.title, window.location.href);
            if(this.state.redirect.data) return <Redirect to={'/rules/' + this.state.redirect.data.id}></Redirect>
            else return <Redirect to={'/rules/create'}></Redirect>
        }

        const header = <div className="p-grid">
                        <div style={{'textAlign':'left'}} className="p-col-9">
                            <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                            <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Поиск по всем полям" size="50"/>
                            </div>
                        <div style={{'textAlign':'right'}} className="p-col-3">
                            <Button onClick = { () => this.setState({redirect: true}) } label="Добавить правило"></Button>
                        </div>
                    </div>;

        return <Card>
                <Fieldset legend="Правила рассылки">
                    <DataTable value={this.state.templates} 
                        style={{marginBottom: '20px'}} 
                        responsive={true} 
                        selectionMode="single" 
                        selection={this.state.template} 
                        onRowSelect={(e)=>this.setState({redirect: e})}
                        onSelectionChange={(e) => this.setState({template: e.value.id})} 
                        paginator={true} 
                        rows={20} 
                        header={header} 
                        globalFilter={this.state.globalFilter} 
                        emptyMessage="Не найдено правил рассылки"
                    >
                        <Column style={{width: '80px', textAlign: 'center'}} field="id" header="#" filter = {true} sortable={true} />
                        <Column field="name" header="Название правила" filter = {true} sortable={true} />
                    </DataTable>
                </Fieldset> 
            </Card>
    }
}