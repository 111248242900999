import React, { Component } from 'react';
import agent from '../../agent';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Redirect } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { unixToDate, guid } from '../../utils/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { store } from './../../store';
import { ON_PAGE, ON_FILTER, ON_REPORT_OPEN} from '../../constants/actionTypes';
import { connect } from 'react-redux';


import Splash from '../Splash';
import User from '../../constants/types/user';

const PAGE_STORAGE_TEMPLATE = 'stateByUrl$1';

const mapStateToProps = state => {
    return {
      first: state.reports.first,
      filter: state.reports.filter,
      page: state.reports.page,
      currentUser: state.auth.user,
    }
};
  
const mapDispatchToProps = dispatch => ({
    onStorePage: (first, page) => { 
        dispatch({ type: ON_PAGE, first: first, page: page})
    },
    onStoreFilter: (filter, page) => { 
        dispatch({ type: ON_FILTER, filter: filter, page: page})
    },
    onReportOpen: (value) => { 
        dispatch({ type: ON_REPORT_OPEN, pageType: value})
    },
});

 const defaultMarketFields = [
    "result",
    "template",
    "date", 
];

const defaultMarketFieldsExport = [
    "result",
    "template",
    "date", 
    "files"
];

const defaulTemplateFields = [
    "city",
    "result",
    "market",
    "date", 
];

const defaulTemplateFieldsExport = [
    "city",
    "result",
    "market",
    "date", 
    "files"
];

class ReportBy extends Component {

    constructor(){
        super();
        this.inThrottle = 0;
        this.isInitBreadCrumbs = false;
        this.state = this.initState()
        this.defaultFields = [];
    }

   
    
    isFilter(field){
        
        return (
            this.marketId ? 
                (['id', 'network', 'market', 'city'].indexOf(field) === -1) 
                    :
                (['id', 'network', 'template'].indexOf(field) === -1)
        )
    }

    isSort(field){
        return (
            this.marketId ? 
                (['network', 'market', 'city'].indexOf(field) === -1)
                    :
                (['network', 'template'].indexOf(field) === -1)
        )
    }

    initState(){
        return Object.assign({
            home: {icon: 'pi pi-home', url: '/#/reports'},
            reports:[], 
            fields: [], 
            reportRedirect: false,  
            checked: [],
            checkedExport: [], 
            exportDialog: false, 
            columnsDialog: false,
            totalResults: false,
            exportAll: 0,
            generateLinkText: 'Сгенерировать файл отчёта',
            generateLinkTextBase: 'Сгенерировать файл отчёта',
            downloadLink: false,
            lists: {},
            items: [
                {label:'Отчеты', url: '/#/reports'},
                {label:'', url: '/#/reports/$1/$2'},
                {label:''},
            ]
        }, this.getPageConfig());
    }

    getPageConfig(){
        const pageConfigStr = localStorage.getItem(PAGE_STORAGE_TEMPLATE.replace('$1', window.location.hash));
        if(pageConfigStr !== null){
            return JSON.parse(pageConfigStr)
        }
        return {
            rows: 10,
            sortField: 'id',
            sortOrder: -1,
        }
    }

    savePageConfig(){
        const {rows, sortField, sortOrder, checked} = this.state;
        localStorage.setItem(PAGE_STORAGE_TEMPLATE.replace('$1', window.location.hash), JSON.stringify({rows, sortField, sortOrder, checked}))
    }

    mapFields(reports){
        let {items, checked} = this.state;
        let fields, dates = [];
        try{
            fields = checked.map(field => reports.allFields.find((el) => {
                if(el.type === 'DATE'){dates.push(el.id)}
                return el.id === field
            }));
            fields = fields.filter(a => a !== undefined)
            fields = [{id: 'id', columnName: '#'}].concat(fields);
    
            reports['resultsValues'] = reports.results.map(result => {
                const resultTmp = {};
                result.columns.forEach(column => {
                    const namedValue = result.columns.find((el) => el.id === column.id + '_name') || false;
    
                    if(!this.isInitBreadCrumbs) {
                        items = this.setBreadCrumbs(items, column);
                    }
    
                    if(dates.indexOf(column.id) > -1 && (Number.parseInt(column.value) + '').length === column.value.length){
                        column.value = unixToDate(column.value);
                    }
    
                    resultTmp[column.id] = namedValue ? namedValue.value : column.value;
                    if(column.id === 'result'){
                        resultTmp[column.id] = <span><span className={column.value === "1" ? 'label-success' : 'label-danger'}></span>&nbsp;<span>{resultTmp[column.id]}</span></span>
                    }
                })
    
                resultTmp['id'] = result.id;
                return resultTmp;
            })
        } catch (e){
            console.log(e)
        }

        this.setState({reports, fields, items, checked, totalResults: reports.totalResults})
    }

    setBreadCrumbs(items, column){
        
        if(column.id === 'network'){
            items[1].url = items[1].url.replace('$1', column.value)
        }

        if(column.id === 'network_name'){
            items[1].label = column.value
        }

        if(column.id === 'template_name' && this.templateId){
            items[2].label = column.value;
            items[1].url = items[1].url.replace('$2', 'templates');
            this.isInitBreadCrumbs = true;
        }

        if(column.id === 'market_name' && this.marketId){
            items[2].label = column.value;
            items[1].url = items[1].url.replace('$2', 'markets');
            this.isInitBreadCrumbs = true;
        }
        return items;
    }

    componentDidMount(){
        this.networkId  = this.props.match.params.network;
        this.templateId  = this.props.match.params.template || false;
        this.marketId  = this.props.match.params.market || false;

        this.defaultFields = this.marketId ? defaultMarketFields : defaulTemplateFields;

        const checked = this.state.checked.length === 0 ? this.defaultFields : this.state.checked;
        const checkedExport = this.marketId ? defaultMarketFieldsExport : defaulTemplateFieldsExport;

        this.setState({checked, checkedExport}, ()=>{
            const {first, page, filter} = store.getState().reports;
            this.setFilterState(filter);
            
            this.setState({
                first: page === window.location.hash ? first : 0
            }, () => {
                this.loadData();
                this.loadResultFilter();

                if(this.templateId) this.loadMarketsFilter();
                if(this.marketId) this.loadTemplatesFilter();

                this.loadUsersFilter();   
            });    
        });
    }

    loadData(){
        agent.Network.reports(this.networkId, 
            {
                filters: this.getFiltersObject(), 
                pageSize: this.state.rows, 
                sortOrder: this.state.sortOrder === 1 ? 'ASC' : 'DESC', 
                orderBy: this.state.sortField, 
                first: this.state.first
            }
        ).then((reports) => {
            this.mapFields(reports);

            if(!this.isInitBreadCrumbs) {
                this.isInitBreadCrumbs = true;
                if(this.marketId){
                    this.loadBreadCrumbs(reports, this.marketId, 'markets');
                }
                if(this.templateId){
                    this.loadBreadCrumbs(reports, this.templateId, 'templates');
                }
            }
        })

        this.props.onStoreFilter(this.getFiltersObject(true), window.location.hash);
    }

    loadBreadCrumbs(reports, id, entity){
        const {items} = this.state;

        if(!reports.results|| reports.results.length === 0){
                
            items[1].url = items[1].url.replace('$1', this.networkId);

            agent.Network.one(this.networkId).then(network => {
                items[1].label = network.name
                this.setState({items})
            });
            if(id > 0){
                if(entity === 'templates') {
                    items[1].url = items[1].url.replace('$2', entity);
                    agent.Template.one(id).then(template => {
                        items[2].label = template.name;
                        this.setState({items})
                    })
                }
                
                if(entity === 'markets') {
                    items[1].url = items[1].url.replace('$2', entity);
                    agent.Market.one(this.networkId, id).then(market => {
                        items[2].label = market.name;
                        this.setState({items})
                    })
                }
            }
        }
    }
    loadUsersFilter(){
        agent.Property.users().then(usersData => {
            const users = usersData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.surname + ' ' + curr.name + ' ' + curr.secondname }]);
            }, []);
            this.setState({users})
        })
    }

    loadMarketsFilter(){
        agent.Market.list(this.networkId).then(marketsData => {
            const markets = marketsData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name}]);
            }, []);
            this.setState({markets})
        })
    }

    loadResultFilter(){
        agent.Property.one(-1).then(resultsData => {
            const results = resultsData.listValues.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name}]);
            }, []);
            this.setState({results})
        })
    }

    loadTemplatesFilter(){
        agent.Network.templates(this.networkId).then(templatesData => {
            const templates = templatesData.reduce((prev, curr) => {
                return prev.concat([{value: curr.id, label: curr.name}]);
            }, []);
            this.setState({templates})
        })
    }

    setFilterState(object){
        const {state} = this;
        let filters = Object.keys(object)
                .reduce((prev, curr) => {
                    const obj = {};
                    obj[`f_${curr}_Value`] = object[curr];
                    return Object.assign(prev, obj)
                }, {});

        Object.assign(state, filters);

        this.setState(state);
    }

    getFiltersObject(store = false){
        let filters = Object.keys(this.state)
                .filter(a => a.indexOf('f_') === 0)
                .filter(a => this.state[a] !== null && this.state[a] !== undefined)
                .reduce((prev, curr) => {
                    const obj = {};
                    obj[curr.replace('f_', '').replace('_Value', '')] = this.state[curr];
                    return Object.assign(prev, obj)
                }, {});

        if(!store && this.templateId){
            filters = Object.assign(filters, {template: this.templateId})
        }

        if(!store && this.marketId){
            filters = Object.assign(filters, {market: this.marketId})
        }

        return filters;
    }
    
    getTemplatesFilter(){
        let templates = this.state.templates || [];
        templates = [{label: 'Любое значение', value: null}].concat(templates);
        return <Dropdown style={{width: '100%'}}
            value={this.state.f_template_Value} options={templates} onChange={(e)=>this.onResultsFilter(e, 'template')}/>
    }

    getMarketFilter(){
        let markets = this.state.markets || [];
        markets = [{label: 'Любое значение', value: null}].concat(markets);
        return <Dropdown style={{width: '100%'}}
            value={this.state.f_market_Value} options={markets} onChange={(e)=>this.onResultsFilter(e, 'market')}/>
    }
    
    getUsersFilter(){
        let users = this.state.users || [];
        users = [{label: 'Любое значение', value: null}].concat(users);
        return <Dropdown style={{width: '100%'}}
            value={this.state.f_user_Value} filter={true} options={users} onChange={(e)=>this.onResultsFilter(e, 'user')}/>
    }

    getResultFilter(){
        let results = this.state.results || [];
        results = [{label: 'Любое значение', value: null}].concat(results);
        return <Dropdown style={{width: '100%'}}
            value={this.state.f_result_Value} options={results} onChange={(e)=>this.onResultsFilter(e, 'result')}/>
    }

    getDateFilter(field){
        return <div className="filter-th">
            <Calendar dateFormat="dd.mm.yy" placeholder='От' value={(this.state[`f_${field}>_Value`] ? new Date(this.state[`f_${field}>_Value`] + 0) : null)} style={{marginBottom: '10px'}} onChange={(e)=>this.onFilter(e, `f_${field}>_Value`, true)}/>
            <Calendar dateFormat="dd.mm.yy" placeholder='До' value={(this.state[`f_${field}<_Value`] ? new Date(this.state[`f_${field}<_Value`] + 0) : null)} onChange={(e)=>this.onFilter(e, `f_${field}<_Value`, true)}/>
        </div>
    }

    getStringFilter(field){
        return <InputText value={(this.state[`f_${field}_Value`] && this.state[`f_${field}_Value`].replace(/%/g, '') ) || '' } onChange={(e)=>this.onFilter(e, `f_${field}_Value`)}/>
    }

    getListFilter(field, id){
        const {lists} = this.state;
        if(lists[id]){
            const list = [{label: 'Любое значение', value: null}].concat(lists[id]);
            return <Dropdown style={{width: '100%'}}
            value={this.state[`f_${field}_Value`]} filter={true} options={list} onChange={(e)=>this.onResultsFilter(e, field)}/>
        } else {
            agent.Property.one(id).then((list) => {
                lists[id] = list.listValues.reduce((prev, curr) => {
                    return prev.concat([{value: curr.name, label: curr.name}]);
                }, []);
                this.setState(lists)
            })
        }
    }
    
    onResultsFilter(event, filterName) {
        const state = this.state || {};
        state[`f_${filterName}_Value`] = event.value;
        state.first = 0;
        
        this.setState(state, () => this.loadData());
    }

    onFilter(e, field, isDate = false){
        const {state} = this;
        if(isDate){
            state[field] = e.value ? e.value.getTime() : undefined;
        } else {
            state[field] = '%' + e.target.value + '%';
        }
        this.setState(state);

        this.throttle(() => {
            return this.setState(state, () => this.loadData());
        }, 700);
    }

    getFilter(field){        
        if(field === "result") {
            return this.getResultFilter();
        }
        else if(field === "market") {
            return this.getMarketFilter();
        }
        else if(field === "user_name") {
            return this.getUsersFilter();
        }
        else if(field === "template") {
            return this.getTemplatesFilter();
        }
        else{
            const fieldData = this.state.fields.find((el)=>el.id && el.id === field);
            if(fieldData.type === "DATE"){
                return this.getDateFilter(field)
            }
            if(fieldData.type === "STRING"){
                return this.getStringFilter(field)
            }
            if(fieldData.type === "LIST"){
                return this.getListFilter(field, fieldData.listId);
            }
        }

        return undefined;
    }

    throttle(func, limit) {
        const args = arguments
        const context = this
        if(this.inThrottle > 0){
            clearTimeout(this.inThrottle);
        }
        this.inThrottle = setTimeout(() => func.apply(context, args), limit)
    }

    onCreateReportLink(){
        const body = {fields: this.state.checkedExport, filters: this.getFiltersObject(), first: this.state.exportAll === 1 ? 0 : this.state.first, pageSize: this.state.exportAll === 1 ? 0 : this.state.rows}
        this.setState({ generateLinkText: 'Генарация отчета', disabled: true});
        agent.Report.xlsx(this.networkId, body).then(data => {
            this.setState({downloadLink: data.link.replace('http://', 'https://'), disabled: false}, ()=>this.onDownload());
        })
    }

    onDownload(){
        window.location.assign(this.state.downloadLink);   
    }

    openDialog(key){
        const state = this.state;
        state[key] = true;
        this.setState(state)
    }

    onCheckExport(e, key){
        let {checkedExport} = this.state;
        if(e.value){
            checkedExport.push(key);
        } else {
            checkedExport = checkedExport.filter(a => a !== key);
        }
        this.setState({checkedExport, downloadLink: ''});
    }

    onCheck(e, key){
        let {checked, reports} = this.state;
        if(e.value){
            checked.push(key);
        } else {
            checked = checked.filter(a => a !== key);
        }
        this.setState({checked}, () => {this.mapFields(reports); this.savePageConfig()});
    }

    onSort(event){
        this.setState({
            sortField: event.sortField,
            sortOrder: event.sortOrder
        }, () => {
            this.loadData(); 
            this.savePageConfig();
        })
    }

    onPage(event) {
        this.setState({
            loading: true
        });

        const startIndex = event.first;

        this.setState({
            first: startIndex,
            rows: event.rows,
            loading: false
        }, () => {
            this.props.onStorePage(startIndex, window.location.hash);
            this.loadData(); 
            this.savePageConfig();
        });
    }

    changeExportPages(e){
        if(e.value!==null) this.setState({exportAll: e.value})
    }


    render = () => {
        const u = (new User()).set(this.props.currentUser);

        const {
            reports, 
            totalResults,
            fields,
            reportRedirect,
            items,
            columnsDialog,
            exportDialog,
            checked,
            checkedExport,
            loading,
            downloadLink,
            generateLinkText,
            disabled
        } = this.state;

        if(reportRedirect !== false) {
            window.history.pushState({}, '', window.location.href);
            return <Redirect to={reportRedirect}/>
        }
        
        var header =<div className = "p-grid" style={{marginBottom: '1.5em'}}>
                        <div className = "p-col" style={{textAlign:'left'}}>
                            {(u.isCreator() || u.isSuperAdmin()) && <Button type="button" icon="pi pi-check" iconPos="left" label="Создать отчёт" onClick={() => this.setState({reportRedirect: `/new-report/${this.networkId}/${this.marketId}`})}></Button>}
                        </div> 
                        <div className = "p-col" style={{textAlign:'right'}}>
                            <Button type="button" icon="pi pi-external-link" iconPos="left" label="XLSX" onClick={() => this.openDialog('exportDialog')}></Button>{' '}
                            <Button type="button" icon="pi pi-th-large" iconPos="left" label="Настроить таблицу" onClick={() => this.openDialog('columnsDialog')}></Button>
                        </div>
                    </div>
        if(reports.totalResults === 0 && !reports.resultsValues){
            return <div className="report card">
                Не удалось получить данные
            </div>
        }
        return ((fields && reports.resultsValues && !loading) && (<div>
                <BreadCrumb className="p-col-12" model={items} home={this.state.home}/>
                <div className="report card">
                    {header}
                    {/* <div className="p-grid" style={{overflowY: 'visible', overflowX: 'scroll', maxWidth: '100%'}}> */}
                    <div className="p-grid">
                        <DataTable value={reports.resultsValues} 
                            style={{marginBottom: '20px'}} 
                            responsive={true} 
                            selectionMode="single" 
                            selection={this.state.report}
                            onSelectionChange={(e) => this.setState({reportRedirect: `/report/${e.value.id}`}, ()=>{this.props.onReportOpen(this.templateId ? 'templates' : 'markets')})} 
                            rows={this.state.rows}
                            totalRecords={totalResults}
                            paginator={true} 
                            lazy={true}
                            first={this.state.first}
                            onPage={(e)=>{this.onPage(e)}}
                            reorderableColumns={true}
                            sortField={this.state.sortField}
                            sortOrder={this.state.sortOrder}
                            onSort={(e)=>{this.onSort(e)}}
                            rowsPerPageOptions={[10,25,50, 100]}
                            emptyMessage="Не найдено"
                        >
                            {
                                fields.map(field => 
                                    (field && field.id && <Column 
                                        key={field.id} 
                                        style={{width: (field.id === 'id' ? '80px' : 'auto')}}
                                        field={field.id} 
                                        header={field.columnName} 
                                        sortable={this.isSort(field.id)}
                                        filter = {this.isFilter(field.id)} 
                                        filterElement={this.getFilter(field.id)} 
                                     />) || null
                                )
                            }
                        </DataTable>
                    </div>
                </div>
                <Dialog visible={columnsDialog} header={'Настроить таблицу'} modal={true} onHide={()=>this.setState({columnsDialog: false})}>
                    <div className="p-grid switches">
                        {reports.allFields.filter(a => a.id!=='files' && a.id!=='user').map(value => {
                            return (
                                <div className="p-col-6" key={guid()}>
                                    <InputSwitch checked={checked.indexOf(value.id) > -1} onChange={(e) => this.onCheck(e, value.id)} />
                                    <span>{value.columnName}</span>
                                </div>
                            )
                        })}
                    </div>
                </Dialog>
                <Dialog visible={exportDialog} header={'Экспортировать таблицу'} modal={true} onHide={()=>this.setState({exportDialog: false, disabled: false, generateLinkText: this.state.generateLinkTextBase, downloadLink: false})}>
                    <div className="p-grid switches">
                        {(reports.allFields).map(value => {
                            return (
                                <div className="p-col-6"  key={guid()}>
                                    <InputSwitch checked={checkedExport.indexOf(value.id) > -1} onChange={(e) => this.onCheckExport(e, value.id)} />
                                    <span>{value.columnName}</span>
                                </div>
                            )
                        })}
                    </div>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <SelectButton 
                                value={this.state.exportAll} 
                                options={[{label: 'Текущая страница', value: 0},{label: 'Все станицы', value: 1},]} 
                                onChange={(e) => this.changeExportPages(e)}
                            ></SelectButton>
                        </div>
                        <div className="p-col-12">
                            {!downloadLink && <Button disabled={disabled} onClick={()=>{this.onCreateReportLink()}} label={generateLinkText} className="p-button-success"></Button>}
                            {downloadLink && <Button className="p-button-success"  label="Скачать файл" icon="pi pi-save" onClick={()=>this.onDownload()} />}
                        </div>
                    </div>
                </Dialog>
            </div> )) || <Splash></Splash>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportBy);
