import {
    ON_PAGE,
    ON_FILTER,
    ON_REPORT_OPEN
  } from '../constants/actionTypes';
  
  export default (state = {first: 0, filter: {}, pageType: 'markets'}, action) => {
    switch (action.type) {
        case ON_PAGE:
            return {
                ...state,
                first: action.first,
                page: action.page
            };
        case ON_FILTER: 
            return {
                ...state,
                filter: action.filter
            };
        case ON_REPORT_OPEN: 
            return {
                ...state,
                pageType: action.pageType
            };    
      default:
        return state;
    }
  };
  