import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { changeState } from './../../utils/utils';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';

import { Dropdown } from 'primereact/dropdown';
import { guid } from './../../utils/utils'
import Template from '../../constants/types/template';


import agent from './../../agent';

const defaultStringSwitches = [
    'Наименование образца',
    'Наименование лаборатории',
    '№ протокола',
    '№ протокола обнаружений',
    'Образцы с обнаружением',
    'Обнаружения',
]
const defaultDateSwitches = [
    'Дата протокола',
    'Дата протокола обнаружений',
]

const STRING = 1;
const DATE = 8;
const BOOLEAN = 2;
const LIST = 4;

export default class TemplateDialog extends Component {
    constructor(){
        super();
        this.state = {
            template: new Template(), 
            defaultStrings: [], 
            defaultDates: [], 
            lists: [],
            stringsColumns: [],
            datesColumns: [],
            listsColumns: [],
            checkboxColumns: [],
            idValues: {}
        }
    }

    loadTemplate(id){
        agent.Template.one(id).then((template)=>{
            template['isNew'] = false;
            this.setState({template: (new Template()).set(template)});
            const state = this.state;

            state['stringsColumns'] = [];
            state['datesColumns'] = [];
            state['listsColumns'] = [];
            state['checkboxColumns'] = [];
            state['idValues'] = {};
            state['$name'] = template.name;

            template.templateFields.forEach(column => {
                const guidValue = guid(`$.${column.templateFieldType.id}.`);
                state['idValues'][guidValue] = column.id;
                state[guidValue.replace('$.', '$r_')] = column.required;
                if(column.templateFieldType.id === STRING ){
                    state.stringsColumns.push({guid: guidValue});   
                    state[guidValue] = column.name;
                }
                if(column.templateFieldType.id === DATE ){
                    state.datesColumns.push({guid: guidValue});
                    state[guidValue] = column.name;
                }
                if(column.templateFieldType.id === LIST ){
                    state.listsColumns.push({guid: guidValue});
                    state[guidValue] = column.listId;
                    state[guidValue.replace('$.', '$_')] = column.name;
                }
                if(column.templateFieldType.id === BOOLEAN ){
                    state.checkboxColumns.push({guid: guidValue});
                    state[guidValue] = column.name;
                }
            })
            this.setState(state);
        })
    }

    componentDidMount(){
        if(this.props.template !== 0){
            this.loadTemplate(this.props.template);
        }

        agent.Property.list().then(
            (listsData) => {
                const lists = listsData.reduce((prev, curr) => {
                    if(curr.id === -1) return prev;
                    return prev.concat([{value: curr.id, label: curr.name}]);
                }, []);
                this.setState({lists})
            }
        );
    }
    
    onChangeState(target){
        changeState(target, this);
    }

    findMaskedKey(target, type){
        if(target.indexOf('.') > 0 && target.indexOf(type) > -1){
            const trg = target.split('.');
            return trg[1];  
        }
        return false;
    }

    getTemplate(){
        const template = {};
        Object.entries(this.state).forEach(([key, value]) => {
            const v = this.findMaskedKey(key, 'template');
            if(v !== false) {
                template[v] = value;
            }
        })
        return template;
    }

    getTemplateData(){
        const templateData = {};
        templateData['templateFields'] = [];
        const {defaultStrings, defaultDates} = this.state;

        for(let i = 0; i < defaultStrings.length; i++) {
            if(defaultStrings[i]) {
                templateData['templateFields'].push({templateFieldType:{id: STRING}, name: defaultStringSwitches[i], required: true });
            }
        }

        for(let i = 0; i < defaultDates.length; i++) {
            if(defaultDates[i]) 
                templateData['templateFields'].push({templateFieldType:{id: DATE}, name: defaultStringSwitches[i], required: true });
        }

        const rawKeys = Object.keys(this.state).map(key => key).filter(a => a.indexOf('$') === 0);
        rawKeys.forEach(key=>{
            if( this.state[key]!='' && key.indexOf('$r_') === -1 && key.indexOf('$_') === -1){ // eslint-disable-line
                if(key.indexOf('$.') > -1){
                    const arrKey = key.split('.');
                    let value;
                    if(Number.parseInt(arrKey[1]) === LIST){
                        value = {templateFieldType:{id: arrKey[1]}, listId: this.state[key], name: this.state[key.replace('$.', '$_')], required: this.state[key.replace('$.', '$r_')]|| true  };
                    } else {
                        value = {templateFieldType:{id: arrKey[1]}, name: this.state[key], required: this.state[key.replace('$.', '$r_')] || true };
                    }
                    if(this.state.idValues[key]) {
                        value['id'] = this.state.idValues[key];
                    }

                    templateData['templateFields'].push(value);
                } else {
                    templateData[key.replace('$','')] = this.state[key]
                }
            }
        })
        return templateData;

    }

    onCreate(){
        const templateData = this.getTemplateData();
        agent.Template.create(templateData).then(
            () => this.props.onUpdate()
        )
    }

    onUpdate(){
        const templateData = this.getTemplateData(), {template} = this.state;
        agent.Template.update(template.id, templateData).then(
            () => this.props.onUpdate()
        )
    }

    onDelete(){
        if(window.confirm(`Действительно удаляем ${this.state.template.name}`)){
            agent.Template.delete(this.state.template.id).then(
                () => this.props.onUpdate()
            )
        }
    }

    onChangeStrings(e, key){
        const {defaultStrings} = this.state;
        defaultStrings[key] = e.value
        this.setState({defaultStrings: defaultStrings})
    }
    onChangeDates(e, key){
        const {defaultDates} = this.state;
        defaultDates[key] = e.value
        this.setState({defaultDates: defaultDates})
    }

    onAddTextField(){
        const {stringsColumns} = this.state;
        stringsColumns.push({guid: guid(`$.${STRING}.`)});
        this.setState({stringsColumns});
    }
    onAddDateField(){
        const {datesColumns} = this.state;
        datesColumns.push({guid: guid(`$.${DATE}.`)});
        this.setState({datesColumns});
    }
    onAddListField(){
        const state = this.state;
        const guidValue = guid(`$.${LIST}.`)
        const guidValueName = guidValue.replace('$.', '$_');
        state.listsColumns.push({guid: guidValue});
        state[guidValueName] = '';
        this.setState(state);
    }
    onAddCheckBoxField(){
        const {checkboxColumns} = this.state;
        checkboxColumns.push({guid: guid(`$.${BOOLEAN}.`)});
        this.setState({checkboxColumns});
    }
    render () {

        const {visible, onHide} = this.props;
        const {id, name, isNew} = this.state.template;
        const {stringsColumns, checkboxColumns, datesColumns, listsColumns, lists} = this.state;
        const footer = (
                 <div className="p-grid modal-form">
                    <div className="p-col-6" style={{textAlign: "left"}}>
                        {id > 0 && <Button className="p-button-danger" label="Удалить шаблон" icon="pi pi-trash" onClick={() => this.onDelete()}/>}
                    </div>
                    <div className="p-col-6">
                        <Button className="p-button-secondary"  label="Отменить" icon="pi pi-times" onClick={() => onHide()} />
                        <Button label="Сохранить" icon="pi pi-check" onClick={() => (id > 0 ? this.onUpdate() : this.onCreate())} />
                    </div>
                </div>
        );
        return (
            <Dialog visible={visible} style={{width:'70vw'}} header={isNew ? 'Новый шаблон' : `Редактирование шаблона`} footer={footer} modal={true} onHide={()=>onHide()}>
                <div className="p-grid modal-form">
                    <div className="p-col-12">
                        <Fieldset legend="Основное">
                            <div className="row">
                                <InputText defaultValue={name} name = "$name" onChange= {e => this.onChangeState(e.target)} className="p-col-6" placeholder="Название шаблона" />
                            </div>
                        </Fieldset>
                    </div>
                    <div className="p-col-12">
                        <Fieldset legend="Поля ввода" >
                            <div className="p-grid switches">
                                {
                                    isNew && defaultStringSwitches.map( (value, key) => (
                                        <div className="p-col-12 p-md-6" key={key}>
                                            <InputSwitch checked={!!this.state.defaultStrings[key]} onChange={(e) => this.onChangeStrings(e, key)} />
                                            <span>{value}</span>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="p-grid custom-fields custom-fields_with-defautls">
                                    {
                                        (stringsColumns).map((value, key)=>
                                            <div className ="p-col-12 p-md-6"  key={value.guid}>
                                                <InputText  value = {this.state[value.guid]} onChange={(e) => {this.onChangeState(e.target)}} name={value.guid} className="p-col-12" placeholder = {`Название поля ${key + 1}`} />
                                                <Checkbox 
                                                    checked={this.state[value.guid.replace('$.', '$r_')]} 
                                                    onChange={(e) => {this.setState(() => {const state = this.state; state[value.guid.replace('$.', '$r_')] = e.checked; return state})}} 
                                                    name={value.guid.replace('$.', '$r_')}
                                                >
                                                </Checkbox> Обязательное поле
                                            </div>    
                                        )
                                    }
                                <div className="p-col-12">
                                    <Button onClick={()=>this.onAddTextField()} label="Добавить поле"></Button>
                                </div>
                            </div>
                        </Fieldset>
                        <Fieldset legend="Даты">
                            <div className="p-grid switches">
                                {
                                    isNew &&  defaultDateSwitches.map((value, key) => 
                                        <div className="p-col-6" key={key}>
                                            <InputSwitch checked={!!this.state.defaultDates[key]} onChange={(e) => this.onChangeDates(e, key)} />
                                            <span>{value}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="p-grid custom-fields custom-fields_with-defautls">
                                    {
                                        (datesColumns).map((value, key)=>
                                            <div className ="p-col-12 p-md-6"  key={value.guid}>
                                                <InputText className="p-col-12"  value = {this.state[value.guid]} onChange={(e) => {this.onChangeState(e.target)}} name={value.guid} placeholder = {`Название даты ${key + 1}`} />
                                                <Checkbox 
                                                    checked={this.state[value.guid.replace('$.', '$r_')]} 
                                                    onChange={(e) => {this.setState(() => {const state = this.state; state[value.guid.replace('$.', '$r_')] = e.checked; return state})}} 
                                                    name={value.guid.replace('$.', '$r_')}
                                                >
                                                </Checkbox> Обязательное поле
                                            </div>    
                                        )
                                    }
                                <div className="p-col-12">
                                    <Button onClick={()=>this.onAddDateField()} label="Добавить поле"></Button>
                                </div>
                            </div>
                        </Fieldset>
                        {lists.length > 0 && <Fieldset legend="Списки">
                            <div className="p-grid custom-fields">
                                    {
                                        (listsColumns).map((value, key)=> {
                                            return <div className="p-col-12" key={value.guid}>
                                                <div className ="p-grid">
                                                    <div className ="p-col-12 p-md-6">
                                                        {this.state[value.guid.replace('$.', '$_')] && <span style={{ marginTop: '-0.8em', marginLeft: '0.2em'}} className="input-label">Название списка в шаблоне</span>}
                                                        <InputText 
                                                            style={{marginBottom: '0.5em', width:'100%'}}
                                                            value={this.state[value.guid.replace('$.', '$_')]} 
                                                            onChange={(e) => {this.onChangeState(e.target)}} 
                                                            name={value.guid.replace('$.', '$_')} placeholder="Название списка в шаблоне"
                                                        >
                                                        </InputText>

                                                        <Checkbox 
                                                            checked={this.state[value.guid.replace('$.', '$r_')]} 
                                                            onChange={(e) => {this.setState(() => {const state = this.state; state[value.guid.replace('$.', '$r_')] = e.checked; return state})}} 
                                                            name={value.guid.replace('$.', '$r_')}
                                                        >
                                                        </Checkbox> Обязательное поле
                                                    </div>
                                                    <div className ="p-col-12 p-md-6">
                                                        <Dropdown style={{width:'100%'}} value = {this.state[value.guid]} onChange={(e) => {this.onChangeState(e.target)}} name={value.guid} options={lists} placeholder={"Выбрать список " + (key + 1) } filter={true} filterBy="label"/>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                <div className="p-col-12">
                                    {lists.length !== listsColumns.length && <Button onClick={()=>this.onAddListField()} label="Добавить поле"></Button>}
                                </div>
                            </div>
                        </Fieldset>}
                        <Fieldset legend="Чекбокс(Да/Нет)">
                            <div className="p-grid custom-fields">
                                    {
                                        (checkboxColumns).map((value, key)=>
                                            <div className ="p-col-12 p-md-6"  key={value.guid}>
                                                <InputText className="p-col-12"  value = {this.state[value.guid]} onChange={(e) => {this.onChangeState(e.target)}} name={value.guid} placeholder = {`Название чекбокса ${key + 1}`} />
                                                <Checkbox 
                                                    checked={this.state[value.guid.replace('$.', '$r_')]} 
                                                    onChange={(e) => {this.setState(() => {const state = this.state; state[value.guid.replace('$.', '$r_')] = e.checked; return state})}} 
                                                    name={value.guid.replace('$.', '$r_')}
                                                >
                                                </Checkbox> Обязательное поле
                                            </div>    
                                        )
                                    }
                                <div className="p-col-12">
                                    <Button onClick={()=>this.onAddCheckBoxField()} label="Добавить поле"></Button>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
            </Dialog>
        )    
    }
}