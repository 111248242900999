import React, {Component} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { changeState, guid } from './../../utils/utils';
import User, {filter2DB} from '../../constants/types/user';
import agent from './../../agent';

export default class UserDialog extends Component {
    constructor(){
        super();
        this.state = {user: new User(), rolesChecked: [], changePassword:false, changeUser: false, newUserRoles: []}
    }

    loadUser(){
        agent.User.one(this.props.user).then((user)=>{
            user['isNew'] = false;
            const rolesChecked = [];
            user.userRoles.filter(a => !a.market && !a.tradeNetwork).forEach(roleValue => {
                rolesChecked.push(roleValue.role.id);
            })
            this.setState({user: (new User()).set(user), rolesChecked})
        })
    }

    componentDidMount(){
        if(this.props.user > 0){
            this.loadUser();
        } else {
            this.setState({user: new User()})
        }
    }
    
    onChangeState(target){
        if(target.name.indexOf('password') > -1){
            this.setState({changePassword: target.value.length > 0, userPasswordError: false})
        } else {
            this.setState({changeUser: true})
        }
        changeState(target, this);
    }

    findMaskedKey(target, type){
        if(target.indexOf('.') > 0 && target.indexOf(type) > -1){
            const trg = target.split('.');
            return trg[1];  
        }
        return false;
    }

    getUser(){
        const user = {};
        Object.entries(this.state).forEach(([key, value]) => {
            const v = this.findMaskedKey(key, 'user');
            if(v !== false) {
                user[v] = value;
            }
        })
        return user;
    }

    onCreate(){
        const user = filter2DB(Object.assign(this.state.user, this.getUser()));
        const {rolesChecked} = this.state;
        agent.User.create(user).then(
            (userData) => {
                rolesChecked.forEach(value => {
                    agent.User.roles(userData.id, {role: {id: value}}).then(()=>{})
                })
                
                this.props.onUpdate()
            }
        )
    }
    onUpdate(){
        const user = filter2DB(Object.assign(this.state.user, this.getUser()));
        const {changePassword, changeUser} = this.state;
        
        if(changePassword){
            console.log(this.state)
            if(this.state['user.password'] !== this.state['user.password_again']){
                this.setState({userPasswordError: true})
            } else {
                agent.User.password(user.id, this.state['user.password']).then(
                    () => this.props.onUpdate()
                )
            }
        }
        if(changeUser){
            agent.User.update(user.id, user).then(
                () => this.props.onUpdate()
            )
        }
    }

    onDelete(){
        if(window.confirm(`Действительно удаляем ${this.state.user.surname} ${this.state.user.name}`)){
            agent.User.delete(this.state.user.id).then(
                () => this.props.onUpdate()
            )
        }
    }

    onSetRole(e, key){
        let {user, rolesChecked} = this.state;
        if(user.isNew) {
            if(e.value){
                rolesChecked.push(key);
            } else {
                rolesChecked = rolesChecked.filter(a => a !== key);
            }
        } else {
            if(e.value){
                agent.User.roles(this.state.user.id, {role: {id: key}})
                    .then(()=>{
                        rolesChecked.push(key);
                        this.setState({rolesChecked}, this.loadUser())
                    })
            } else {
                const role = this.state.user.userRoles.find(a=>a.role.id === key);
                agent.User.removeRole(this.state.user.id, role.id).then(()=>{
                    rolesChecked = rolesChecked.filter(a => a !== key);
                    this.setState({rolesChecked}, this.loadUser());
                })
            }
        }
    }

    render () {

        const {visible, onHide, roles} = this.props; 
        const {user, rolesChecked, changePassword, changeUser} = this.state;

        const footer = (
                 <div className="p-grid modal-form">
                    <div className="p-col-6" style={{textAlign: "left"}}>
                        {(!user.isSuperAdmin() && user.id > 0) && <Button className="p-button-danger" label="Удалить этого пользователя" icon="pi pi-trash" onClick={() => this.onDelete()} />}
                    </div>
                    <div className="p-col-6">
                        <Button className="p-button-secondary"  label="Отменить" icon="pi pi-times" onClick={() => onHide()} />
                        {(changePassword || changeUser) && <Button label="Сохранить" icon="pi pi-check" onClick={() => (user.id > 0 ? this.onUpdate() : this.onCreate())} />}
                    </div>
                </div>
        );


        return (
            <Dialog visible={visible} header={user.isNew ? 'Новый пользователь' : `Редактирование пользователя`} footer={footer} style={{minWidth: '600px'}} modal={true} onHide={()=>onHide()}>
                <div className="p-grid modal-form">
                    <div className="p-col-6">
                        <Fieldset legend="Основное">
                            <InputText defaultValue={user.surname} name = "user.surname" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Фамилия" />
                            <InputText defaultValue={user.name} name = "user.name" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Имя" />
                            <InputText defaultValue={user.secondname} name = "user.secondname" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Отчество" />
                            <InputText defaultValue={user.mail} name = "user.mail" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="email" />                            
                        </Fieldset>
                        <Fieldset legend="Пароль">
                            <InputText type="password" name = "user.password" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Пароль" />
                            <InputText type="password" name = "user.password_again" onChange= {e => this.onChangeState(e.target)} className="p-col-12" placeholder="Пароль ещё раз" />

                            {this.state.userPasswordError && <div><span className="label-danger"></span>Пароли не совпадают</div>}
                        </Fieldset>
                    </div>
                    <div className="p-col-6">
                        <Fieldset legend="Роли в системе">
                            {roles.map(role => 
                                    <div className="p-grid switches" key={guid()}>
                                        <div className = "p-col-12">
                                            <InputSwitch checked={rolesChecked.indexOf(role.id) > -1} onChange={(e) => this.onSetRole(e, role.id)} />
                                            <span>{role.description}</span>
                                        </div>
                                    </div>
                            )}
                        </Fieldset>
                    </div>
                </div>
            </Dialog>
        )    
    }

}