import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import MarketDialog from './MarketDialog';
import agent from './../../agent';
import { Link } from 'react-router-dom';

export class Markets extends Component {

    constructor(){
        super();
        this.state = {network:{name:''}, markets: [], dialog: false, market: 0};
    }

    loadMarkets(){
        this.networkId = this.props.match.params.id;
        agent.Market.list(this.networkId).then((markets)=>{
            this.setState({markets: markets, dialog: false})
        })
        agent.Network.one(this.networkId).then((network)=>{
            this.setState({network: network, dialog: false})
        })
    }
    
    componentDidMount() {
       this.loadMarkets();
    }

    showDialog(e){
        if(e){
            this.setState({dialog: true, market: e.data.id})
        } else{
            this.setState({dialog: true, market: 0})
        }
    }

    onUpdateMarkets(){
        this.loadMarkets();
    }

    render() {

        const {network, market, dialog} = this.state;
        const header = <div className="p-grid">
                        <div style={{'textAlign':'right'}} className="p-col-12">
                            <Button onClick = { () => this.showDialog() } label="Добавить гипермаркет"></Button>
                        </div>
                    </div>;
        
        return <Card>
                <Fieldset legend={<Link to = "/networks/">{'Торговая сеть "' + (network ? network.name : '') + '"'}</Link>}>
                    <DataTable value={this.state.markets} 
                        style={{marginBottom: '20px'}} 
                        responsive={true}
                        selection={this.state.market} 
                        onRowSelect={(e)=>this.showDialog(e)}
                        onSelectionChange={(e) => this.setState({market: e.value.id})} 
                        selectionMode="single" 
                        paginator={true} 
                        rows={10} 
                        header={header} 
                        emptyMessage="Нет магазинов с такими параметрами"
                    >
                        <Column style={{width: '80px', textAlign: 'center'}} field="id" header="#" filter = {true} sortable={true} />
                        <Column field="name" header="Название" filter = {true} sortable={true} />
                        <Column field="city" header="Город" filter = {true} sortable={true} />
                    </DataTable>
                </Fieldset> 
                {dialog && <MarketDialog network = {this.networkId} market={market} onUpdate = {() => this.onUpdateMarkets()} visible = {true} onHide = {() => this.setState({dialog: false})}/>}
            </Card>
    }
}