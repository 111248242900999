import React, {Component} from 'react';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { changeState, guid } from './../../utils/utils';
import agent from './../../agent';

export default class UserMailsDialog extends Component {
    constructor(){
        super();
        this.state = {rules: [], search:''}
    }

    loadUser(){
        agent.User.rules(this.props.user).then(rulesResponse => {
            const rules = rulesResponse.reduce((prev, curr) => {
                return prev.concat([curr.id]);
            }, []);
            this.setState({rules})
        })
        agent.Rules.list().then(rulesData => {
            this.setState({rulesData})
        })
    }

    componentDidMount(){
        this.loadUser();
    }
    
    onChangeState(target){
        changeState(target, this);
    }

    onSetRole(e, id){
        let rules = this.state.rules;
        if(e.value){
            rules.push(id);
            agent.Rules.addUser(id, this.props.user).then(()=>{this.setState({rules})});
        } else {
            rules = rules.filter(a => a !== id);
            agent.Rules.removeUser(id, this.props.user).then(()=>{this.setState({rules})});
        }
    }
    
    onSearch(e){
        this.setState({search: e.target.value})
    }
    render () {

        const {visible, onHide} = this.props;
        const {rules, rulesData} = this.state;

        return (
            <Dialog visible={visible} header={`Назначить правила рассылки`}style={{minWidth: '600px'}} modal={true} onHide={()=>onHide()}>
               
                <div className="p-grid">
                    {
                        rulesData && rulesData.map(rule => <div key={rule.id} className="p-col-12 p-md-4 p-lg-3"> 
                            <div className="p-grid switches" key={guid()}>
                                <div className = "p-col-12">
                                    <InputSwitch checked={rules.indexOf(rule.id) > -1} onChange={(e) => this.onSetRole(e, rule.id)} />
                                    <span>{rule.name}</span>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </Dialog>
        )    
    }

}