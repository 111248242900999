import Base from "./base";

export default class Property extends Base{
    constructor(){
        super();
        this.name = '';
        this.listValues = [''];
        this.isNew = true;
        this.id = 0;
        
        this.fields2DB = [
            'name',
            'description',
            'listValues',
            'id'
        ]

        this.getPatchedListValues = (listValues) => {
            return listValues
                        .filter(a => a.length > 0)
                        .reduce((prev, curr) => {
                            return prev.concat([{list_id: this.id, name: curr}]);
                        }, []);
        }

        this.getUnPatchedListValues = (listValues) => {
            if(listValues.length === 0) return [''];
            return listValues
                    .reduce((prev, curr) => prev.concat([curr.name]), []);
        }
    }
}
