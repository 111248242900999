import React, { Component } from 'react';
import classNames from 'classnames';
import md5 from 'js-md5';
import { connect } from 'react-redux';
import { store } from './store';
import { deleteCookie } from './utils/utils';
import Splash from './components/Splash';
import { Link } from 'react-router-dom';

const mapStateToProps = state => {
    return {
      user: state.auth.user
    }};

const mapDispatchToProps = () => {};
    
export class AppProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    logout(){
        this.setState({loading: true})
        deleteCookie('user');
        deleteCookie('pass');
        window.location.reload();
    }

    render() {
        const {user} = store.getState().auth;
        if(!!this.state.loading){
            return <Splash></Splash>
        }
        if(user){
            return (
                <div className="layout-profile">
                    <div>
                        <img className="user-avatar" src={"https://www.gravatar.com/avatar/" + md5(user.mail)} alt="" />
                    </div>
                    <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{`${user.surname} ${user.name} ${user.secondname}`} </span>
                        <i className="pi pi-fw pi-cog"/>
                    </button>
                    <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                        <li><Link className="p-link p-link_profile" to="/profile"><i className="pi pi-fw pi-user"/><span>Профиль</span></Link></li>
                        <li><button className="p-link" onClick={()=>this.logout()}><i className="pi pi-fw pi-power-off"/><span>Выход</span></button></li>
                    </ul>
                </div>
            );
        } else {
            return null
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppProfile);


