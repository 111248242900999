import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = 'https://cus.cucertif.ru/api';
// open -na Google\ Chrome --args --user-data-dir='/tmp' --disable-web-security
// const API_ROOT = '/api';
// const encode = encodeURIComponent;
let user = {email: '_email', password: '_password'};
const responseBody = res => res.body;
const tokenPlugin = req => {
  req.auth(unescape(encodeURIComponent(user.email)), unescape(encodeURIComponent(user.password)));
}


const requests = {
  del: 
    url =>
      superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: 
    url =>
      superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: 
    (url, body) =>
      superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: 
    (url, body) =>
      superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

const Auth = {
  login: 
    (_email, _password) =>
      superagent.post(`${API_ROOT}/user/auth`, {mail: _email, password: _password}).then(responseBody),
  save: 
    user =>
      requests.put('/user', { user })
};

const User = {
  profile: 
    () =>
      requests.get('/user'),
  list: 
    () =>
      requests.get('/users'),
  rolesList: 
    () =>
      requests.get('/users/roles'),
  profilePassword: 
    (password) => 
      requests.post(`/user/password`, {password: password}),
  password: 
    (id, password) => 
      requests.post(`/users/${id}/password`, {password: password}),
  roles: 
    (id, roles) => 
      requests.put(`/users/${id}/roles`, roles),
  removeRole: 
    (id, roleId) =>
      requests.del(`/users/${id}/roles/${roleId}`),
  one: 
    id =>
      requests.get(`/users/${id}`),
  create: 
    user => 
      requests.put(`/users/`, user),
  update: 
    (id, user) => 
      requests.post(`/users/${id}`, user),
  delete: 
    (id) => 
      requests.del(`/users/${id}`),
  rules:
    (id) => 
      requests.get(`/users/${id}/rules`)
}

const Property = {
  list: () =>
    requests.get('/list'),
  one: 
    id =>
      requests.get(`/list/${id}`),
  users: 
    () =>
      requests.get('/list/users'),
  create: 
    list => {
      return requests.put(`/list/`, list) 
    },
  update: 
    (id, list) => 
      requests.post(`/list/${id}`, list),
  delete: 
    (id) => 
      requests.del(`/list/${id}`),
}


const Template = {
  list: () =>
    requests.get('/template'),
  one: 
    id =>
      requests.get(`/template/${id}`),
  create: 
    template => 
      requests.put(`/template/`, template),
  update: 
    (id, template) => 
      requests.post(`/template/${id}`, template),
  delete: 
    (id) => 
      requests.del(`/template/${id}`),
  reports:
    (id, filter = {}) => 
      requests.post(`/template/${id}/report`, filter),
}

const Network = {
  list: () =>
    requests.get('/networks'),
  one: 
    id =>
      requests.get(`/networks/${id}`),
  create: 
    network => 
      requests.post(`/networks/`, network),
  update: 
    (id, network) => 
      requests.put(`/networks/${id}`, network),
  template: 
    (id, template) => 
      requests.post(`/networks/${id}/templates/${template}`),
  templates: 
    (id) => 
      requests.get(`/networks/${id}/templates`),
  reports: 
    (id, filter = {}) => 
      requests.post(`/networks/${id}/report`,filter),
  delete: 
    (id) => 
      requests.del(`/networks/${id}`),
}

const Market = {
  list: (network) =>
    requests.get(`/networks/${network}/markets`),
  one: 
    (network, id) =>
      requests.get(`/networks/${network}/markets/${id}`),
  create: 
    (network, market) => 
      requests.put(`/networks/${network}/markets`, market),
  update: 
    (network, id, market) => 
      requests.post(`/networks/${network}/markets/${id}`, market),
  delete: 
    (network, id) => 
      requests.del(`/networks/${network}/markets/${id}`),
}

const Rules = {
  list: () =>
    requests.get(`/mail`),
  one: 
    (id) =>
      requests.get(`/mail/${id}`),
  create: 
    (rule) => 
      requests.post(`/mail`, rule),
  update: 
    (id, rule) => 
      requests.post(`/mail/${id}`, rule),
  delete: 
    (id) => 
      requests.del(`/mail/${id}`),
  users:
    (id) => requests.get(`/mail/${id}/users`),
  addUser:
    (id, userId) => requests.post(`/mail/${id}/users`, {id: userId}),
  removeUser:
    (id, userId) => requests.del(`/mail/${id}/users/${userId}`),
}


const Report = {
  one: 
    (id) =>
      requests.get(`/reports/${id}`),
  create:
    (report) => 
      requests.post('/reports', report),
  download:
    (id) =>
      requests.get(`/reports/${id}/download`),
  xlsx:
    (id, data) =>
      requests.post(`/networks/${id}/report/download`, data), 
  delete:
    (id) =>
      requests.del(`/reports/${id}`)
}


const Files = {
  postUrl: 
    () => 
      `${API_ROOT}/files/`,
  url: 
    () => 
      `${API_ROOT}/files/public/attachment`,
  delete: 
    (id) => 
      requests.del(`/files/${id}`)
}

export default {
  Files,
  Auth,
  User,
  Network,
  Rules,
  Template,
  Property,
  Market,
  Report,
  setToken: _user => { user = _user; },
  getToken: () => 'Basic ' + btoa(unescape(encodeURIComponent(user.email)) + ':' + unescape(encodeURIComponent(user.password)))
};
